import axios from 'axios';

export const getGeoLocation = (text: string): Promise<any> => {
  const geocode = process.env.REACT_APP_GEOCODE_URL;
  const apiKey = process.env.REACT_APP_GEOCODE_API_KEY || '';

  return axios
    .get(
      `${geocode}?${new URLSearchParams({
        api_key: apiKey,
        text,
      })}`,
    )
    .then((result) => result.data);
};
