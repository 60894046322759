import React from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';
import DefaultLayout from 'src/components/layout/DefaultLayout';
import QuizStep from 'src/components/QuizSteps';
import { QuizProvider } from 'src/context/QuizContext';
import { quizResources } from 'src/quiz-resources/main';

const Quiz = () => (
  <QuizProvider data={quizResources}>
    <DefaultLayout>
      <Switch>
        {quizResources.steps.map((step) => (
          <Route exact path={`${quizResources.baseUrl}/${step.id}`} key={step.id}>
            <QuizStep stepData={step} />
          </Route>
        ))}
        <Redirect to={`${quizResources.baseUrl}/${quizResources.steps[0].id}`} />
      </Switch>
    </DefaultLayout>
  </QuizProvider>
);

export default Quiz;
