import React, { useEffect, useRef } from 'react';

import { PaymentOrderDescription, PaymentOrderStatus } from 'src/types';
import { TMessageEventData } from './types';

export type CardPaymentProps = {
    formMerchantData: unknown;
    className?: string;
    onOrderDeclined: () => void;
    onOrderStartProcessing: () => void;
    onOrderApproved: (orderData: PaymentOrderDescription) => void;
}

const CardPayment = (props: CardPaymentProps) => {
  const {
    formMerchantData, onOrderApproved, onOrderDeclined, onOrderStartProcessing,
  } = props;

  useEffect(() => {
    const listener = (event: MessageEvent<TMessageEventData>) => {
      if (!event) {
        return;
      }

      if (event.data.type !== 'orderStatus') {
        return;
      }

      const { order } = event.data.response;
      switch (order.status) {
        case PaymentOrderStatus.PROCESSING:
          onOrderStartProcessing();
          break;
        case PaymentOrderStatus.APPROVED:
        case PaymentOrderStatus.REFUNDED:
          onOrderApproved(order);
          break;
        case PaymentOrderStatus.DECLINED:
          onOrderDeclined();
          break;
      }
    };

    window.addEventListener('message', listener);

    return () => {
      window.removeEventListener('message', listener);
    };
  },
  [
    formMerchantData,
    onOrderStartProcessing,
    onOrderApproved,
    onOrderDeclined,
  ]);

  const form = useRef();

  const containerId = 'solid-payment-form-container';

  useEffect(() => {
    // @ts-ignore
    // eslint-disable-next-line no-undef
    if (!form.current && formMerchantData && PaymentFormSdk) {
      // @ts-ignore
      // eslint-disable-next-line no-undef
      form.current = PaymentFormSdk.init({
        merchantData: formMerchantData,
      });
    }
  }, [formMerchantData, form]);

  return <div title="card" id={containerId} />;
};

export default CardPayment;
