import React from 'react';
import QuizScreenLayout from 'src/components/layout/QuizScreenLayout';
import cn from 'classnames';
import { ReactComponent as MaleIcon } from 'src/assets/images/virgo/iconMan.svg';
import FemaleIcon from 'src/assets/images/virgo/iconWoman.png';
import NonBinaryIcon from 'src/assets/images/virgo/iconNonbinary.png';
import { GenderStepData } from 'src/types';
import styles from './GenderChooseScreen.module.scss';

export type GenderScreenProps = {
  onChosen: (choose: string) => void;
  onBack: () => void;
} & Omit<GenderStepData, 'id' | 'dataKey' | 'screenType' | 'analytic'>;

const GenderChooseScreen = ({
  onChosen,
  onBack,
  stepOrder,
  totalSteps,
  content,
}: GenderScreenProps) => {
  const choiceClasses = cn({
    [styles.choice]: true,
  });

  const handleClick = (choice: string) => () => {
    onChosen(choice);
  };

  return (
    <QuizScreenLayout
      title={content.title}
      illustration={content.illustration}
      stepOrder={stepOrder}
      totalSteps={totalSteps}
      radio
      onBack={onBack}
    >
      <p className={styles.subtitle}>{content.subtitle}</p>
      <ul className={styles.choiceList}>
        <li className={styles.choiceListItem} onClick={handleClick('male')}>
          <div className={choiceClasses}>
            <MaleIcon className={styles.choiceImg} title="male" />
            <p className={styles.choiceText}>Male</p>
          </div>
        </li>
        <li className={styles.choiceListItem} onClick={handleClick('female')}>
          <div className={choiceClasses}>
            <img src={FemaleIcon} alt="female" className={styles.choiceImg} title="female" />
            <p className={styles.choiceText}>Female</p>
          </div>
        </li>
        { content.shouldShowNonBinary && (
          <li className={styles.choiceListItem} onClick={handleClick('female')}>
            <div className={choiceClasses}>
              <img src={NonBinaryIcon} alt="non binary" className={styles.choiceImg} title="non binary" />
              <p className={styles.choiceText}>Non-binary</p>
            </div>
          </li>
        ) }
      </ul>
    </QuizScreenLayout>
  );
};

export default GenderChooseScreen;
