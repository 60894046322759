import React, { useEffect, useState } from 'react';
import ProcessingScreenLoaderCircle from '../ProcessingScreenLoaderCircle';
import styles from './ProcessingScreenLoader.module.scss';

export type ProcessingScreenLoaderProps = {
  onFinished: () => void;
}

const ProcessingScreenLoader = ({ onFinished }: ProcessingScreenLoaderProps) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null;
    const setTimeoutValue = (time: number) => setTimeout(() => {
      setValue((prev) => prev + 1);
    }, time);

    if (value !== 100) {
      timer = value === 60 || value === 90 ? setTimeoutValue(1500) : setTimeoutValue(100);
    } else {
      setTimeout(() => onFinished(), 1500);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [value]);

  return (
    <div className={styles.container}>
      <ProcessingScreenLoaderCircle value={value} />
      <div className={styles.block}>
        <p className={styles.value}>
          {value}
          %
        </p>
      </div>
    </div>
  );
};

export default ProcessingScreenLoader;
