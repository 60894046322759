export type UserLocation = {
  region: LocationRegion;
  country: LocationCountry;
};

export enum LocationRegion {
  UNKNOWN = 'UNKNOWN',
  CALIFORNIA = 'CA',
}

export enum LocationCountry {
  UNKNOWN = 'UNKNOWN',
  US = 'US',
  UA = 'UA',
  AE = 'AE',
  AD = 'AD',
  AL = 'AL',
  AT = 'AT',
  BA = 'BA',
  BG = 'BG',
  BE = 'BE',
  CH = 'CH',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DK = 'DK',
  GB = 'GB',
  GR = 'GR',
  IE = 'IE',
  IT = 'IT',
  IS = 'IS',
  HR = 'HR',
  HU = 'HU',
  EE = 'EE',
  ES = 'ES',
  FI = 'FI',
  FR = 'FR',
  LI = 'LI',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MK = 'MK',
  MT = 'MT',
  NL = 'NL',
  NO = 'NO',
  PL = 'PL',
  PT = 'PT',
  RO = 'RO',
  RS = 'RS',
  SE = 'SE',
  SI = 'SI',
  SK = 'SK',
  SM = 'SM',
  TR = 'TR',
  VA = 'VA',
}

export const europeanCountries = [
  LocationCountry.AD,
  LocationCountry.AL,
  LocationCountry.AT,
  LocationCountry.BA,
  LocationCountry.BG,
  LocationCountry.BE,
  LocationCountry.CH,
  LocationCountry.CY,
  LocationCountry.CZ,
  LocationCountry.DE,
  LocationCountry.DK,
  LocationCountry.GB,
  LocationCountry.GR,
  LocationCountry.IE,
  LocationCountry.IS,
  LocationCountry.IT,
  LocationCountry.HR,
  LocationCountry.HU,
  LocationCountry.EE,
  LocationCountry.ES,
  LocationCountry.FI,
  LocationCountry.FR,
  LocationCountry.LI,
  LocationCountry.LT,
  LocationCountry.LU,
  LocationCountry.LV,
  LocationCountry.MC,
  LocationCountry.MD,
  LocationCountry.ME,
  LocationCountry.MK,
  LocationCountry.MT,
  LocationCountry.NL,
  LocationCountry.NO,
  LocationCountry.PL,
  LocationCountry.PT,
  LocationCountry.RO,
  LocationCountry.RS,
  LocationCountry.SE,
  LocationCountry.SI,
  LocationCountry.SK,
  LocationCountry.SM,
  LocationCountry.UA,
  LocationCountry.VA,
];
