import React, { useState } from 'react';
import cn from 'classnames';
import { ReactComponent as BurgerIcon } from 'src/assets/images/burger.svg';
import { ReactComponent as CrossIcon } from 'src/assets/images/cross.svg';
import Drawer from '@material-ui/core/Drawer';
import {
  getTermsPagePath,
  getContactUsPagePath,
  getPrivacyPolicyPagePath,
} from 'src/constants';

import styles from './HeaderMenu.module.scss';

export interface HeaderMenuProps {
    className?: string;
}

export const HeaderMenu = ({ className }: HeaderMenuProps) => {
  const [isOpened, setIsOpened] = useState(false);

  const onControlClick = () => {
    setIsOpened((value) => !value);
  };

  const onClose = () => {
    setIsOpened(false);
  };

  return (
    <div className={cn(styles.root, className)}>
      <span className={styles.menuControl} onClick={onControlClick}>
        <BurgerIcon className={styles.menuControlIcon} />
      </span>

      <Drawer open={isOpened} onClose={onClose} PaperProps={{ className: styles.menuContentWrapper }}>
        <div className={styles.menuContent}>
          <div className={styles.menuCloseWrapper}>
            {/* TODO change to button */}
            <span className={styles.menuClose} onClick={onClose}>
              <CrossIcon className={styles.menuCloseIcon} />
            </span>
          </div>
          <nav>
            <ul className={styles.menuNavList}>
              <li className={styles.menuNavListItem}>
                <a className={styles.menuNavLink} href={getTermsPagePath()}>Terms of use</a>
              </li>
              <li className={styles.menuNavListItem}>
                <a className={styles.menuNavLink} href={getPrivacyPolicyPagePath()}>Privacy policy</a>
              </li>
              <li className={styles.menuNavListItem}>
                <a className={styles.menuNavLink} href={getContactUsPagePath()}>Contact us</a>
              </li>
            </ul>
          </nav>
        </div>
      </Drawer>
    </div>
  );
};

export default HeaderMenu;
