import React from 'react';
import stars from 'src/assets/images/stars.svg';
import ButtonBack from 'src/components/common/ButtonBack/ButtonBack';
import Button from 'src/components/common/Button';
import { TestimonialStepData } from 'src/types';
import styles from './TestimonialScreen.module.scss';

export type TestimonialScreenProps = {
  onBack: () => void;
  onContinue: () => void;
} & Omit<TestimonialStepData, 'id' | 'screenType' | 'analytic'>;

const TestimonialScreen = ({
  content, onBack, onContinue,
}: TestimonialScreenProps) => (
  <div className={styles.wrapper}>
    <div className={styles.background} />
    <div className={styles.content}>
      <ButtonBack onClick={onBack} />
      <div className={styles.container}>
        <div className={styles.quotes}>
          <img className={styles.stars} src={stars} alt="stars" />
          <p className={styles.text}>{content.text}</p>
        </div>
        <div className={styles.person}>
          <img src={content.avatar} alt="woman" />
          <p className={styles.name}>{content.name}</p>
          <p className={styles.region}>{content.address}</p>
        </div>
      </div>
    </div>
    <div className={styles.buttonWrapper}>
      <Button wrapperClassName={styles.button} onClick={onContinue}>
        Continue
      </Button>
    </div>
  </div>
);

export default TestimonialScreen;
