import React from 'react';
import cn from 'classnames';

import styles from './StoreLink.module.scss';

const StoreLink = ({
  className = '',
  wrapperClassName = '',
  src = '',
  href = 'https://nebulaapp.onelink.me/AaEA/b4f5f4a9',
}) => (
  <a
    href={href}
    target="_blank"
    rel="noreferrer"
    className={wrapperClassName}
  >
    <img src={src} alt="Store" className={cn(styles.link, className)} />
  </a>
);

export default StoreLink;
