import React, { CSSProperties } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import MaterialModal from '@material-ui/core/Modal';
import NFade from './ModalFade';

const useStyles = makeStyles((theme: Theme) => createStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:focus': {
      outline: 'none',
    },

  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export type ModalProps = {
  open: boolean,
  onClose: () => void,
  BackdropProps?: {
    className?: string,
    timeout: number,
    style: CSSProperties,
  },
  children: React.ReactNode
}

const Modal = (props: ModalProps) => {
  const {
    children,
    open,
    onClose,
    BackdropProps,
  } = props;

  const classes = useStyles();

  const mergedBackdropProps = {
    ...BackdropProps,
    style: {
      ...BackdropProps?.style,
      backgroundColor: 'rgba(60, 60, 60, 0.1)',
      backdropFilter: 'blur(10px)',
    },
  };

  return (
    <MaterialModal
      open={open}
      onClose={onClose}
      BackdropProps={mergedBackdropProps}
      className={classes.modal}
    >
      <NFade
        in={open}
      >
        {children}
      </NFade>
    </MaterialModal>
  );
};

export default Modal;
