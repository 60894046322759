/* eslint-disable react/jsx-props-no-spreading */
import QuizScreenLayout from 'src/components/layout/QuizScreenLayout';
import React from 'react';
import { FormikHelpers, useFormik } from 'formik';
import Button from 'src/components/common/Button';
import Input from 'src/components/common/Input/Input';
import { EmailStepData } from 'src/types';
import { useMarkdown } from 'src/hooks/useMarkdown';
import Checkbox from 'src/components/common/Checkbox/Checkbox';
import EmailScreenSuggestionsList from './EmailScreenSuggestionsList';
import styles from './EmailScreen.module.scss';
import { validationSchema } from './validationSchema';

export type EmailFormHelpers = FormikHelpers<{
  email: string;
  emailConsent: boolean;
}>;

export type EmailScreenProps = {
  onBack: () => void;
  initialValue?: string;
  onSubmit: (
    data: { email: string; emailConsent: boolean },
    helpers: EmailFormHelpers
  ) => Promise<void>;
} & Omit<EmailStepData, 'id' | 'dataKey' | 'screenType' | 'analytic'>;

const EmailScreen = (props: EmailScreenProps) => {
  const {
    content, stepOrder, totalSteps, initialValue = '', onBack, onSubmit,
  } = props;

  const parsedTip = useMarkdown(content.tip);

  const {
    getFieldProps,
    values,
    errors,
    touched,
    setFieldValue,
    isSubmitting,
    handleSubmit,
  } = useFormik({
    initialValues: {
      email: initialValue,
      emailConsent: true,
    },
    validationSchema,
    onSubmit: async (submitValues, helpers) => {
      await onSubmit(submitValues, helpers);
    },
  });

  return (
    <QuizScreenLayout
      onBack={onBack}
      illustration={content.illustration}
      title={content.title}
      stepOrder={stepOrder}
      totalSteps={totalSteps}
    >
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.container}>
          <h3 className={styles.subtitle}>{content.subtitle}</h3>
          <ul className={styles.formControlList}>
            <li className={styles.formControlListItem}>
              <Input
                {...getFieldProps('email')}
                error={touched.email && !!errors.email}
                errorText={errors.email}
                className={styles.input}
                placeholder="Your best email address"
              />
              <EmailScreenSuggestionsList
                input={values.email}
                onSelect={(value) => setFieldValue('email', value)}
              />
            </li>
            <li className={styles.formControlListItem}>
              <Checkbox
                className={styles.checkbox}
                {...getFieldProps('emailConsent')}
                checked={getFieldProps('emailConsent').value}
                label="I would like to receive my report and personalised offers & updates from Nebula via this email."
              />
            </li>
          </ul>
        </div>
        <div className={styles.block}>
          <Button type="submit" disabled={isSubmitting}>
            Continue
          </Button>
          <div className={styles.tipWrapper} dangerouslySetInnerHTML={{ __html: parsedTip }} />
        </div>
      </form>
    </QuizScreenLayout>
  );
};

export default EmailScreen;
