import { QuizStepData, StepTypes } from 'src/types';
import React from 'react';
import DateQuizStep from './DateQuizStep';
import PlaceQuizStep from './PlaceQuizStep';
import TimeQuizStep from './TimeQuizStep';
import ColorPickerQuizStep from './ColorPickerQuizStep';
import InformationalQuizStep from './InformationalQuizStep';
import GenderQuizStep from './GenderQuizStep';
import EmailQuizStep from './EmailQuizStep';
import IntroQuizStep from './IntroQuizStep';
import SingleAnswerQuizStep from './SingleAnswerQuizStep';
import MultiAnswerQuizStep from './MultiAnswerQuizStep';
import NameQuizStep from './NameQuizStep';
import ProcessingQuizStep from './ProcessingQuizStep';
import SupportQuizStep from './SupportQuizStep';
import TestimonialQuizStep from './TestimonialQuizStep';
import IntroPartnerQuizStep from './IntroPartnerQuizStep';

export type QuizStepProps = {
  stepData: QuizStepData
};

const QuizStep = ({ stepData }: QuizStepProps) => {
  switch (stepData.screenType) {
    case StepTypes.DATE:
      return <DateQuizStep id={stepData.id} />;
    case StepTypes.PLACE:
      return <PlaceQuizStep id={stepData.id} />;
    case StepTypes.TIME:
      return <TimeQuizStep id={stepData.id} />;
    case StepTypes.COLOR_PICKER:
      return <ColorPickerQuizStep id={stepData.id} />;
    case StepTypes.INFORMATIONAL:
      return <InformationalQuizStep id={stepData.id} />;
    case StepTypes.GENDER:
      return <GenderQuizStep id={stepData.id} />;
    case StepTypes.EMAIL:
      return <EmailQuizStep id={stepData.id} />;
    case StepTypes.INTRO:
      return <IntroQuizStep id={stepData.id} />;
    case StepTypes.INTRO_PARTNER:
      return <IntroPartnerQuizStep id={stepData.id} />;
    case StepTypes.SINGLE_ANSWER:
      return <SingleAnswerQuizStep id={stepData.id} />;
    case StepTypes.MULTI_ANSWER:
      return <MultiAnswerQuizStep id={stepData.id} />;
    case StepTypes.NAME:
      return <NameQuizStep id={stepData.id} />;
    case StepTypes.PROCESSING:
      return <ProcessingQuizStep id={stepData.id} />;
    case StepTypes.SUPPORT:
      return <SupportQuizStep id={stepData.id} />;
    case StepTypes.TESTIMONIAL:
      return <TestimonialQuizStep id={stepData.id} />;
    default: {
      // TODO(Logger)
      console.error('Unknown step data', stepData);
      return null;
    }
  }
};

export default QuizStep;
