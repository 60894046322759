import React from 'react';
import { ProcessingStepData } from 'src/types';
import styles from './ProcessingScreen.module.scss';
import ProcessingScreenLoaderList from './ProcessingScreenLoaderList';
import ProcessingScreenLoader from './ProcessingScreenLoader';

export type ProcessingScreenProps = {
  onFinished: () => void;
} & Omit<ProcessingStepData, 'screenType' | 'id' | 'analytic'>;

const ProcessingScreen = ({ content, onFinished }: ProcessingScreenProps) => (
  <div className={styles.container}>
    <h1 className={styles.title}>
      {content.title}
    </h1>
    <ProcessingScreenLoader onFinished={onFinished} />
    <ProcessingScreenLoaderList list={content.stages} />
  </div>
);

export default ProcessingScreen;
