import React from 'react';
import ColorPickerScreen from 'src/components/QuizScreens/ColorPickerScreen';
import { useQuizStep } from 'src/context/QuizContext';
import { ColorPickerStepData } from 'src/types';

export type ColorPickerQuizStepProps = {
  id: string;
};

const ColorPickerQuizStep = ({ id }: ColorPickerQuizStepProps) => {
  const {
    back,
    next,
    stepData,
    updateUserPreferences,
  } = useQuizStep(id);

  if (!stepData) {
    // TODO(Logger)
    // TODO add fallback
    console.warn(`Data for ${id} step not found`);
    return null;
  }

  const {
    content,
    stepOrder,
    totalSteps,
    dataKey,
  } = stepData as ColorPickerStepData;

  const goBack = async () => back();

  const onSelect = async (color: string) => {
    updateUserPreferences(dataKey, color);
    await next();
  };

  return (
    <ColorPickerScreen
      content={content}
      onBack={goBack}
      onSelect={onSelect}
      stepOrder={stepOrder}
      totalSteps={totalSteps}
    />
  );
};

export default ColorPickerQuizStep;
