import React from 'react';
import QuizScreenLayout from 'src/components/layout/QuizScreenLayout';
import { ColorPickerStepData } from 'src/types';
import styles from './ColorPickerScreen.module.scss';
import ColorPickerScreenButton from './ColorPickerScreenButton';

export type ColorPickerScreenProps = {
  onBack: () => void;
  onSelect: (color: string) => void;
} & Omit<ColorPickerStepData, 'dataKey' | 'id' | 'screenType' | 'analytic'>

const colorPickerData = [
  { color: '#9B51E0' },
  { color: '#56CCF2' },
  { color: '#2F80ED' },
  { color: '#27AE60' },
  { color: '#F2C94C' },
  { color: '#F2994A' },
  { color: '#EB5757' },
  { color: '#151515', borderColor: '#5E5E5E' },
  { color: '#FFFFFF' },
];

const ColorPickerScreen = ({
  content,
  stepOrder,
  totalSteps,
  onBack,
  onSelect,
}: ColorPickerScreenProps) => (
  <QuizScreenLayout
    stepOrder={stepOrder}
    totalSteps={totalSteps}
    title={content.title}
    illustration={content.illustration}
    onBack={onBack}
  >
    <div className={styles.container}>
      <div className={styles.colorListWrapper}>
        <ul className={styles.colorList}>
          { colorPickerData.map(({ color, borderColor }) => (
            <li className={styles.colorListItem} key={color}>
              <ColorPickerScreenButton
                color={color}
                borderColor={borderColor}
                onSelect={onSelect}
              />
            </li>
          )) }
        </ul>
      </div>
    </div>
  </QuizScreenLayout>
);

export default ColorPickerScreen;
