/* eslint-disable react/require-default-props */
import React, { FC } from 'react';
import { Element } from 'react-scroll';
import cn from 'classnames';
import Button from 'src/components/common/Button';
import styles from './HeaderButton.module.scss';
import commonStyles from '../../common.module.scss';

interface Props {
  onGetReport?: () => void;
  buttonClassName: string;
  isActive?: boolean;
  onScrollToButton?: () => void;
}
export const HeaderButton: FC<Props> = ({
  onGetReport,
  onScrollToButton,
  buttonClassName,
  isActive = true,
}) => (
  <Element name="paymentButton">
    <div className={cn(commonStyles.sectionButtonWrapper, styles.buttonWrapper, buttonClassName)}>
      <Button
        disabled={!isActive}
        wrapperClassName={commonStyles.sectionButtonContainer}
        className={commonStyles.sectionButton}
        onClick={onGetReport || onScrollToButton}
      >
        Get my report
      </Button>
    </div>
  </Element>
);

export default HeaderButton;
