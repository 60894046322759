import React from 'react';
import { DateStepData } from 'src/types';
import DateScreen from 'src/components/QuizScreens/DateScreen';
import { useQuizStep } from 'src/context/QuizContext';

export type DateQuizStepProps = {
  id: string;
};

const DateQuizStep = ({
  id,
}: DateQuizStepProps) => {
  const {
    back,
    next,
    stepData,
    updateUserPreferences,
  } = useQuizStep(id);

  if (!stepData) {
    // TODO(Logger)
    // TODO add fallback
    console.warn(`Data for ${id} step not found`);
    return null;
  }

  const {
    content,
    dataKey,
    totalSteps,
    stepOrder,
  } = stepData as DateStepData;

  const goBack = async () => back();

  const onDateSelected = async (date: string) => {
    updateUserPreferences(dataKey, date);
    await next();
  };

  return (
    <DateScreen
      totalSteps={totalSteps}
      stepOrder={stepOrder}
      content={content}
      onBack={goBack}
      onSelected={onDateSelected}
    />
  );
};

export default DateQuizStep;
