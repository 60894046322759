import React from 'react';
import MultipleAnswerScreen from 'src/components/QuizScreens/MultipleAnswerScreen';
import { MultiAnswerStepData } from 'src/types';
import { useQuizStep } from 'src/context/QuizContext';

export type MultiAnswerQuizStepProps = {
  id: string;
};

const MultiAnswerQuizStep = ({ id }: MultiAnswerQuizStepProps) => {
  const {
    back,
    next,
    stepData,
    updateUserPreferences,
    userPreferences,
  } = useQuizStep(id);

  if (!stepData) {
    // TODO(Logger)
    // TODO add fallback
    console.warn(`Data for ${id} step not found`);
    return null;
  }

  const {
    stepOrder,
    totalSteps,
    content,
    dataKey,
  } = stepData as MultiAnswerStepData;

  const initialValue = Array.isArray(userPreferences[dataKey])
    ? userPreferences[dataKey] as Array<string> : [];

  const goBack = async () => back();

  const onChosen = async (value: string[]) => {
    updateUserPreferences(dataKey, value);
    await next();
  };

  return (
    <MultipleAnswerScreen
      content={content}
      stepOrder={stepOrder}
      totalSteps={totalSteps}
      onBack={goBack}
      initialValue={initialValue}
      onChosen={onChosen}
    />
  );
};

export default MultiAnswerQuizStep;
