import { QUIZ_ANALYTIC_EVENTS } from 'src/services/analytics';

export * from './PakcageDTO';

export * from './Payment';

export enum LoadingStatuses {
  IDLE = 1,
  PENDING = 2,
  FULFILLED,
  FAILED,
}

export type MarkdownText = string;

export enum StepTypes {
  DATE = 'DATE',
  PLACE = 'PLACE',
  TIME = 'TIME',
  COLOR_PICKER = 'COLOR_PICKER',
  INFORMATIONAL = 'INFORMATIONAL',
  GENDER = 'GENDER',
  EMAIL = 'EMAIL',
  INTRO = 'INTRO',
  INTRO_PARTNER = 'INTRO_PARTNER',
  SINGLE_ANSWER = 'SINGLE_ANSWER',
  MULTI_ANSWER = 'MULTI_ANSWER',
  NAME = 'NAME',
  PROCESSING = 'PROCESSING',
  SUPPORT = 'SUPPORT',
  TESTIMONIAL = 'TESTIMONIAL',
}

export interface QuizData {
  steps: Array<QuizStepData>;
  baseUrl: string;
  nextStage: string;
}

interface BaseStepData {
  id: string;
  screenType: StepTypes;
  content: Record<string, unknown>;
  stepOrder?: number;
  totalSteps?: number;
  canNotBackToScreen?: boolean;
  analytic?: Partial<{
    baseEvent: QUIZ_ANALYTIC_EVENTS
  }>
}

export type QuizStepData = IntroStepData
  | IntroPartnerStepData
  | InformationalStepData
  | SupportStepData
  | GenderStepData
  | PlaceStepData
  | DateStepData
  | TimeStepData
  | ProcessingStepData
  | SingleAnswerStepData
  | MultiAnswerStepData
  | EmailStepData
  | ColorPickerStepData
  | TestimonialStepData
  | NameStepData;

export interface IntroStepData extends BaseStepData {
  screenType: StepTypes.INTRO;
  content: {
    title: string;
    subtitle: string;
    illustration: string;
  }
}

export interface IntroPartnerStepData extends BaseStepData {
  screenType: StepTypes.INTRO_PARTNER;
  content: {
    title: string;
    illustration: string;
  }
}

export interface SupportStepData extends BaseStepData {
  screenType: StepTypes.SUPPORT;
  content: {
    title: string;
    description: MarkdownText;
    list: Array<string>;
  }
}

export interface InformationalStepData extends BaseStepData {
  screenType: StepTypes.INFORMATIONAL;
  content: {
    title: string;
    text: MarkdownText;
    illustration: string;
  }
}

export interface GenderStepData extends BaseStepData {
  screenType: StepTypes.GENDER;
  dataKey: string;
  content: {
    title: string;
    subtitle: string;
    illustration: string;
    shouldShowNonBinary?: boolean;
  }
}

export interface PlaceStepData extends BaseStepData {
  screenType: StepTypes.PLACE;
  content: {
    title: string;
    subtitle: string;
    illustration: string;
  }
  locationDataKey: string;
  placeDataKey: string;
}

export interface DateStepData extends BaseStepData {
  screenType: StepTypes.DATE;
  dataKey: string;
  content: {
    title: string;
    subtitle: string;
    illustration: string;
  }
}

export interface TimeStepData extends BaseStepData {
  screenType: StepTypes.TIME,
  dataKey: string;
  content: {
    title: string;
    subtitle: string;
    illustration: string;
    tip: string;
  }
}

export interface ProcessingStepData extends BaseStepData {
  screenType: StepTypes.PROCESSING;
  content: {
    title: string;
    stages: Array<string>;
  };
}

export interface AnswerStepDataAnswer {
  text: string;
  emoji?: string;
}

export interface SingleAnswerStepData extends BaseStepData {
  screenType: StepTypes.SINGLE_ANSWER;
  dataKey: string;
  content: {
    title: string;
    answers: Array<AnswerStepDataAnswer>;
    illustration: string;
  }
}

export interface MultiAnswerStepData extends BaseStepData {
  screenType: StepTypes.MULTI_ANSWER;
  dataKey: string;
  content: {
    title: string;
    answers: Array<AnswerStepDataAnswer>;
    illustration: string;
  }
}

export interface EmailStepData extends BaseStepData {
  screenType: StepTypes.EMAIL;
  dataKey: string;
  content: {
    title: string;
    subtitle: string;
    illustration: string;
    tip: MarkdownText;
  }
}

export interface ColorPickerStepData extends BaseStepData {
  screenType: StepTypes.COLOR_PICKER;
  dataKey: string;
  content: {
    title: string;
    illustration: string;
  };
}

export interface NameStepData extends BaseStepData {
  screenType: StepTypes.NAME;
  content: {
    title: string;
    subtitle: string;
    illustration: string;
  };
  dataKey: string;
}

export interface TestimonialStepData extends BaseStepData {
  screenType: StepTypes.TESTIMONIAL;
  content: {
    text: string;
    avatar: string;
    name: string;
    address: string;
  }
}
