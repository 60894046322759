import React from 'react';
import logo from 'src/assets/images/logo.svg';
import logo_mobile from 'src/assets/images/logo_mobile.svg';
import styles from './Header.module.scss';
import HeaderMenu from './HeaderMenu';

const Header = () => (
  <div className={styles.header}>
    <picture>
      <source srcSet={logo} media="(min-width: 600px)" />
      <img src={logo_mobile} alt="Nebula" />
    </picture>
    <HeaderMenu className={styles.menu} />
  </div>
);

export default Header;
