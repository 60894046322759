/* eslint-disable arrow-body-style */
import React, { useCallback, useState } from 'react';
import Header from 'src/components/layout/Header/Header';
import PaymentDialog from 'src/components/common/PaymentDialog';
import { PaymentOrderDescription } from 'src/types';
import styles from './Billing.module.scss';
import SectionQuestions from './SectionQuestions';
import SectionHeader from './SectionHeader';
import { useBilling } from './useBilling';
import Copyright from '../../components/common/Copyright';

const Billing = () => {
  const [isPaymentDialogVisible, setIsPaymentDialogVisible] = useState(false);
  const [isAgree, setIsAgree] = useState(false);
  const {
    price,
    getPaymentUrls,
    paymentSucceed,
    paypalPaymentProcessing,
    cardPaymentProcessing,
    paymentFailed,
  } = useBilling();

  const onGetReport = () => {
    setIsPaymentDialogVisible(true);
  };

  const onPaymentDialogClose = () => {
    setIsPaymentDialogVisible(false);
  };

  const onPaymentSuccess = useCallback((orderData: PaymentOrderDescription) => {
    setIsPaymentDialogVisible(false);
    paymentSucceed(orderData);
  }, [paymentSucceed]);

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <Header />
        </div>
        <SectionHeader onGetReport={onGetReport} isAgree={isAgree} setIsAgree={setIsAgree} />
        <SectionQuestions onGetReport={onGetReport} isAgree={isAgree} />
      </div>
      <div className={styles.copyright}>
        <Copyright />
      </div>
      <PaymentDialog
        onPaymentFailed={paymentFailed}
        onPaypalPaymentProcessing={paypalPaymentProcessing}
        onCardPaymentProcessing={cardPaymentProcessing}
        onPaymentSuccess={onPaymentSuccess}
        isOpen={isPaymentDialogVisible}
        onClose={onPaymentDialogClose}
        price={price}
        getPaymentUrls={getPaymentUrls}
      />
    </>
  );
};

export default Billing;
