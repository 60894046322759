import amplitude from 'amplitude-js';
import { QUIZ_ANALYTIC_EVENTS } from './events';

export class AmplitudeService {
  private instance: amplitude.AmplitudeClient;

  constructor(apiKey: string) {
    this.instance = amplitude.getInstance();
    this.instance.init(apiKey);
  }

  setUserProperties(userData: {[key: string]: unknown}) {
    this.instance.setUserProperties(userData);
  }

  launch() {
    if (!localStorage.getItem('launched')) {
      localStorage.setItem('launched', 'true');
      this.instance.logEvent('launch_first_time');
    } else if (!sessionStorage.getItem('launched')) {
      sessionStorage.setItem('launched', 'true');
      this.instance.logEvent('launch');
    }
  }

  trackQuizEvent(quizEvent: QUIZ_ANALYTIC_EVENTS) {
    this.instance.logEvent(quizEvent);
  }

  paymentCardChosen() {
    this.instance.logEvent('payment_card_chosen');
  }

  paymentPaypalChosen() {
    this.instance.logEvent('payment_paypal_chosen');
  }

  paymentError() {
    this.instance.logEvent('payment_error');
  }

  paymentSuccess({ productId }: { productId: string }) {
    this.instance.logEvent('payment_success', {
      product_id: productId,
    });
  }

  lpIntroOfferOpen() {
    this.setUserProperties({
      landing_page_id: 'lp_intro_offer_1',
    });
    this.instance.logEvent('lp_introoffer_open');
  }

  lpIntroOfferStandardClick({ productId }: { productId: string }) {
    this.instance.logEvent('lp_introoffer_standard_click', {
      product_id: productId,
    });
  }

  lpIntroOfferGetStartedClick() {
    this.instance.logEvent('lp_introoffer_getstarted_click');
  }
}
