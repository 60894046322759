import React from 'react';
import cn from 'classnames';
import styles from './ColorPickerScreenButton.module.scss';

export type ColorPickerScreenButtonProps = {
  color: string;
  borderColor?: string;
  onSelect: (color: string) => void;
  className?: string;
}

const ColorPickerScreenButton = ({
  color,
  borderColor,
  onSelect,
  className,
}: ColorPickerScreenButtonProps) => {
  const onClick = () => {
    onSelect(color);
  };

  return (
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    <button
      type="button"
      onClick={onClick}
      className={cn(styles.color, className)}
      style={{
        background: color,
        borderColor: borderColor || color,
      }}
    />
  );
};

export default ColorPickerScreenButton;
