import React from 'react';
import SingleAnswerScreen from 'src/components/QuizScreens/SingleAnswerScreen';
import { SingleAnswerStepData } from 'src/types';
import { useQuizStep } from 'src/context/QuizContext';

export type SingleAnswerQuizStepProps = {
  id: string;
};

const SingleAnswerQuizStep = ({ id }: SingleAnswerQuizStepProps) => {
  const {
    back,
    next,
    stepData,
    updateUserPreferences,
  } = useQuizStep(id);

  if (!stepData) {
    // TODO(Logger)
    // TODO add fallback
    console.warn(`Data for ${id} step not found`);
    return null;
  }

  const {
    content,
    stepOrder,
    totalSteps,
    dataKey,
  } = stepData as SingleAnswerStepData;

  const goBack = async () => back();

  const onChosen = async (value: string) => {
    updateUserPreferences(dataKey, value);
    await next();
  };

  return (
    <SingleAnswerScreen
      content={content}
      onChosen={onChosen}
      stepOrder={stepOrder}
      totalSteps={totalSteps}
      onBack={goBack}
    />
  );
};

export default SingleAnswerQuizStep;
