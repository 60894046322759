import React from 'react';
import { useLocationComponent } from './useLocationComponent';

const LocationComponent = ({ children }: { children: React.ReactNode }) => {
  useLocationComponent();

  return <>{children}</>;
};

export default LocationComponent;
