import React from 'react';
import { Provider } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useApp } from 'src/hooks/useApp';
import { ServicesContext } from 'src/context';
import styles from 'src/App.module.scss';
// import PostQuiz from 'src/pages/PostQuiz/PostQuiz';
import { BASE_ROUTES } from 'src/constants/routes';
// import LoveReadingLanding1 from 'src/pages/LoveReadingLanding1';
import Quiz from 'src/pages/Quiz';
// import ShortQuiz from 'src/pages/ShortQuiz';
// import ExtendedLanding from 'src/pages/ExtendedLanding';
// import CookiesAgreement from 'src/components/common/CookiesAgreement';
// import PrivateRoute from 'src/components/common/PrivateRoute/PrivateRoute';
// import CompatibilityReportFunnel from 'src/pages/CompatibilityReportFunnel';
import Billing from 'src/pages/Billing';
// import AdditionalDiscount from 'src/pages/AdditionalDiscount';
// import Numerology from 'src/pages/Numerology';
// import Tarot from 'src/pages/Tarot';
import Terms from 'src/pages/Terms';
import Success from 'src/pages/Success';
import ContactUs from 'src/pages/ContactUs';
import LocationComponent from './location/ui/LocationComponent';

const App = () => {
  const { isAppLoaded, store, services } = useApp();

  if (!isAppLoaded || !store || !services) {
    return <>Loading</>;
  }

  return (
    <Provider store={store}>
      <ServicesContext.Provider value={services}>
        <LocationComponent>
          <div className={styles.app}>
            <Switch>
              <Route path={BASE_ROUTES.TERMS} exact>
                <Terms />
              </Route>

              <Route path={BASE_ROUTES.SUCCESS} exact>
                <Success />
              </Route>

              <Route path={BASE_ROUTES.CONTACT_US} exact>
                <ContactUs />
              </Route>

              {/* <Route path={BASE_ROUTES.LOVE_READING_1} exact>
              <LoveReadingLanding1 />
            </Route>

            <Route path={BASE_ROUTES.EXTENDED_LANDING} exact>
              <ExtendedLanding />
            </Route> */}

              <Route path={BASE_ROUTES.BILLING} exact>
                <Billing />
              </Route>

              {/* <Route path={BASE_ROUTES.ADDITIONAL_DISCOUNT} exact>
              <AdditionalDiscount />
            </Route>

            <Route path={BASE_ROUTES.NUMEROLOGY} exact>
              <Numerology />
            </Route>

            <Route path={BASE_ROUTES.TAROT} exact>
              <Tarot />
            </Route> */}

              {/* <PrivateRoute path={BASE_ROUTES.POST_QUIZ} exact>
              <CookiesAgreement />
              <PostQuiz />
            </PrivateRoute> */}

              {/* <Route path={BASE_ROUTES.SHORT_QUIZ}>
              <ShortQuiz />
            </Route>

            <Route path={BASE_ROUTES.COMPATIBILITY_REPORT}>
              <CookiesAgreement />
              <CompatibilityReportFunnel />
            </Route> */}

              <Route path={BASE_ROUTES.QUIZ}>
                <Quiz />
              </Route>

              <Redirect to={BASE_ROUTES.QUIZ} />
            </Switch>
          </div>
        </LocationComponent>
      </ServicesContext.Provider>
    </Provider>
  );
};

export default App;
