import React from 'react';
import checked from 'src/assets/images/checked.png';
import styles from './ProcessingScreenLoaderList.module.scss';
import './ProcessingScreenLoader.scss';

export interface ProcessingScreenLoaderListProps {
  list: string[];
}

const ProcessingScreenLoaderList = ({ list }: ProcessingScreenLoaderListProps) => (
  <ul className={styles.list}>
    {list.map((item, idx) => (
      <li key={item}>
        <img className={`img-${idx}`} src={checked} alt="done" />
        {item}
      </li>
    ))}
  </ul>
);

export default ProcessingScreenLoaderList;
