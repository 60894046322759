import geoLocationAutocomplete from './constants';
import { AppThunk } from '../types/AppThunk';
import { getGeoLocation } from '../../api/apiGeoLocation';
import {
  IGeoLocationList,
  TGeolocationAutocompleteFailureAction, TGeolocationAutocompleteResetAction,
  TGeolocationAutocompleteSuccessAction,
} from './types';

const geolocationAutocompleteSuccess = (
  autoCompleteList: IGeoLocationList[],
): TGeolocationAutocompleteSuccessAction => ({
  type: geoLocationAutocomplete.SUCCESS,
  autoCompleteList,
});

const geolocationAutocompleteFailure = (): TGeolocationAutocompleteFailureAction => ({
  type: geoLocationAutocomplete.FAILURE,
});

export const geolocationAutocompleteReset = (): TGeolocationAutocompleteResetAction => ({
  type: geoLocationAutocomplete.RESET,
});

export const fetchAutocompletePlaces = (value: string): AppThunk => async (dispatch) => {
  try {
    const { features } = await getGeoLocation(value);
    dispatch(geolocationAutocompleteSuccess(features));
  } catch (err) {
    dispatch(geolocationAutocompleteFailure());
  }
};
