import React, { ReactNode } from 'react';
import ProgressBar from 'src/components/common/ProgressBar';
import ButtonBack from 'src/components/common/ButtonBack/ButtonBack';
import ScreenIllustration from 'src/components/common/ScreenIllustration';
import styles from './QuizScreenLayout.module.scss';

export type QuizContentProps = {
  children: ReactNode;
  radio?: boolean;
  stepOrder?: number;
  totalSteps?: number;
  title?: string;
  illustration?: string;
  onBack: () => void;
}

const QuizScreenLayout = ({
  title,
  illustration,
  onBack,
  stepOrder = 1,
  radio,
  children,
  totalSteps = 22,
}: QuizContentProps) => (
  <div className={styles.container}>
    <div className={styles.block}>
      <div className={styles.mobile}>
        <ProgressBar step={stepOrder} totalSteps={totalSteps} />
      </div>
      <div className={styles.title}>
        <ButtonBack className={styles.back} onClick={onBack} />
        <div className={styles.titleContainer}>
          <h1>{title}</h1>
          <ScreenIllustration
            className={styles.illustration}
            src={illustration}
            alt="screen-illustration"
          />
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.desktop}>
          <ProgressBar step={stepOrder} totalSteps={totalSteps} />
        </div>
        <div className={radio ? styles.radioContent : styles.content}>{children}</div>
      </div>
    </div>
  </div>
);

export default QuizScreenLayout;
