import React from 'react';
import SupportScreen from 'src/components/QuizScreens/SupportScreen';
import { useQuizStep } from 'src/context/QuizContext';
import { SupportStepData } from 'src/types';

export type SupportQuizStepProps = {
  id: string;
};

const SupportQuizStep = ({ id }: SupportQuizStepProps) => {
  const { back, next, stepData } = useQuizStep(id);

  if (!stepData) {
    // TODO(Logger)
    // TODO add fallback
    console.warn(`Data for ${id} step not found`);
    return null;
  }

  const {
    totalSteps,
    stepOrder,
    content,
  } = stepData as SupportStepData;

  const goBack = async () => back();

  const onContinue = async () => next();

  return (
    <SupportScreen
      content={content}
      stepOrder={stepOrder}
      totalSteps={totalSteps}
      onBack={goBack}
      onContinue={onContinue}
    />
  );
};

export default SupportQuizStep;
