import React from 'react';
import TimeScreen from 'src/components/QuizScreens/TimeScreen';
import { TimeStepData } from 'src/types';
import { useQuizStep } from 'src/context/QuizContext';

export type TimeQuizStepProps = {
  id: string;
};

const TimeQuizStep = ({ id }: TimeQuizStepProps) => {
  const {
    back,
    next,
    stepData,
    updateUserPreferences,
  } = useQuizStep(id);

  if (!stepData) {
    // TODO(Logger)
    // TODO add fallback
    console.warn(`Data for ${id} step not found`);
    return null;
  }

  const {
    content,
    stepOrder,
    totalSteps,
    dataKey,
  } = stepData as TimeStepData;

  const goBack = async () => back();

  const onTimeSelected = async (time: string) => {
    updateUserPreferences(dataKey, time);
    await next();
  };

  return (
    <TimeScreen
      stepOrder={stepOrder}
      totalSteps={totalSteps}
      onBack={goBack}
      content={content}
      onSelected={onTimeSelected}
    />
  );
};

export default TimeQuizStep;
