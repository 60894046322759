import React from 'react';
import InlineLink from 'src/components/common/InlineLink';
import { getPrivacyPolicyPagePath, getTermsPagePath } from 'src/constants';
import Button from 'src/components/common/Button';
import ScreenIllustration from 'src/components/common/ScreenIllustration';
import { IntroStepData } from 'src/types';
import styles from './IntroScreen.module.scss';

export type IntroScreenProps = {
  onStart: () => void;
} & Omit<IntroStepData, 'id' | 'screenType' | 'analytic'>;

const IntroScreen = ({
  onStart,
  content,
}: IntroScreenProps) => (
  <div className={styles.container}>
    <h1 className={styles.title}>{content.title}</h1>
    <p className={styles.text}>{content.subtitle}</p>
    <ScreenIllustration
      wrapperClass={styles.imageWrapper}
      src={content.illustration}
    />
    <div className={styles.link}>
      <Button
        onClick={onStart}
        wrapperClassName={styles.buttonWrapper}
        className={styles.button}
      >
        Check compatibility
      </Button>
      <p className={styles.policy}>
        By continuing I agree with
        {' '}
        <InlineLink className={styles.link} href={getTermsPagePath()}>Terms of Service</InlineLink>
        ,
        {' '}
        <InlineLink className={styles.link} href={getPrivacyPolicyPagePath()}>Privacy Policy</InlineLink>
        {/* , and the
        {' '}
        <InlineLink className={styles.link} href={getCookiePolicyPagePath()}>Cookie Policy</InlineLink> */}
        .
      </p>
    </div>
  </div>
);

export default IntroScreen;
