import React from 'react';
import { useQuizStep } from 'src/context/QuizContext';
import { InformationalStepData } from 'src/types';
import InformationalScreen from 'src/components/QuizScreens/InformationalScreen';

export type InformationalQuizStepProps = {
  id: string;
};

const InformationalQuizStep = ({ id }: InformationalQuizStepProps) => {
  const {
    back,
    next,
    stepData,
  } = useQuizStep(id);

  if (!stepData) {
    // TODO(Logger)
    // TODO add fallback
    console.warn(`Data for ${id} step not found`);
    return null;
  }

  const {
    stepOrder,
    totalSteps,
    content,
  } = stepData as InformationalStepData;

  const onContinue = async () => next();

  const goBack = async () => back();

  return (
    <InformationalScreen
      content={content}
      totalSteps={totalSteps}
      stepOrder={stepOrder}
      onContinue={onContinue}
      onBack={goBack}
    />
  );
};

export default InformationalQuizStep;
