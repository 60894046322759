import React from 'react';
import cn from 'classnames';
import styles from './InlineLink.module.scss';

export type InlineLinkProps = {
  href: string;
  children: React.ReactElement | string;
  className?: string
};

export default function InlineLink({
  href,
  children,
  className,
}: InlineLinkProps) {
  return (
    <a href={href} className={cn(styles.link, className)}>
      {children}
    </a>
  );
}
