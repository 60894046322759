import React, { useState } from 'react';
import moment from 'moment';
import Button from 'src/components/common/Button/Button';
import Select from 'src/components/common/Select/Select';
import { generateDates } from 'src/utils/generateDates';
import { transformToMinutesFormat } from 'src/utils/transformToMinutesFormat';
import Checkbox from 'src/components/common/Checkbox/Checkbox';
import QuizScreenLayout from 'src/components/layout/QuizScreenLayout';
import { TimeStepData } from 'src/types';
import styles from './TimeScreen.module.scss';

const hoursList = generateDates(1, 12, 'increment');
const minutesList = transformToMinutesFormat(generateDates(0, 59, 'increment'));
const partOfDay = ['AM', 'PM'];

export type TimeScreenProps = {
  onSelected: (time: string) => void;
  onBack: () => void;
} & Omit<TimeStepData, 'dataKey' | 'id' | 'screenType' | 'analytic'>

const TimeScreen = ({
  content,
  stepOrder,
  totalSteps,
  onBack,
  onSelected,
}: TimeScreenProps) => {
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
  };

  const [time, setTime] = useState({
    hours: '12',
    minutes: '00',
    part: 'AM',
  });

  const handleDateChange = (e: React.ChangeEvent<HTMLSelectElement>) => setTime((prev) => ({
    ...prev,
    [e.target.name]: e.target.value,
  }));

  const handleButtonClick = () => {
    const timeOfBirth = moment(`${time.hours}:${time.minutes} ${time.part}`, 'LT').format('HH:mm:ss');
    onSelected(timeOfBirth);
  };

  return (
    <QuizScreenLayout
      title={content.title}
      illustration={content.illustration}
      stepOrder={stepOrder}
      totalSteps={totalSteps}
      onBack={onBack}
    >
      <div>
        <p className={styles.text}>{content.subtitle}</p>
        <div className={styles.select}>
          <Select data={hoursList} name="hours" value={time.hours} onChange={handleDateChange} />
          <Select
            data={minutesList}
            name="minutes"
            value={time.minutes}
            onChange={handleDateChange}
          />
          <Select data={partOfDay} name="part" value={time.part} onChange={handleDateChange} />
        </div>
        <Checkbox name="time" checked={checked} onChange={handleCheckboxChange} label={content.tip} />
      </div>
      <Button onClick={handleButtonClick}>
        Continue
      </Button>
    </QuizScreenLayout>
  );
};

export default TimeScreen;
