import React from 'react';
import IntroScreen from 'src/components/QuizScreens/IntroScreen';
import { IntroStepData } from 'src/types';
import { useQuizStep } from 'src/context/QuizContext';

export type IntroQuizStepProps = {
  id: string;
};

const IntroQuizStep = ({
  id,
}: IntroQuizStepProps) => {
  const { next, stepData } = useQuizStep(id);

  if (!stepData) {
    // TODO(Logger)
    // TODO add fallback
    console.warn(`Data for ${id} step not found`);
    return null;
  }

  const {
    stepOrder,
    totalSteps,
    content,
  } = stepData as IntroStepData;

  const onStart = async () => next();

  return (
    <IntroScreen
      stepOrder={stepOrder}
      totalSteps={totalSteps}
      onStart={onStart}
      content={content}
    />
  );
};

export default IntroQuizStep;
