/* eslint-disable react/jsx-props-no-spreading */
import React, { ImgHTMLAttributes } from 'react';
import cn from 'classnames';

import styles from './ScreenIllustration.module.scss';

export type ScreenIllustrationProps = {
  wrapperClass?: string;
} & ImgHTMLAttributes<HTMLImageElement>;

const ScreenIllustration = ({
  wrapperClass, className, alt = '', ...otherProps
}: ScreenIllustrationProps) => (
  <div className={cn(styles.wrapper, wrapperClass)}>
    <img className={cn(styles.image, className)} alt={alt} {...otherProps} />
  </div>
);

export default ScreenIllustration;
