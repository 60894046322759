import React from 'react';
import IntroPartnerScreen from 'src/components/QuizScreens/IntroPartnerScreen';
import { useQuizStep } from 'src/context/QuizContext';
import { IntroPartnerStepData } from 'src/types';

export type IntroPartnerQuizStepProps = {
  id: string;
}

const IntroPartnerQuizStep = ({ id }: IntroPartnerQuizStepProps) => {
  const { next, stepData, back } = useQuizStep(id);

  if (!stepData) {
    // TODO(Logger)
    // TODO add fallback
    console.warn(`Data for ${id} step not found`);
    return null;
  }

  const {
    content,
    stepOrder,
    totalSteps,
  } = stepData as IntroPartnerStepData;

  const goBack = async () => back();

  const onContinue = async () => next();

  return (
    <IntroPartnerScreen
      content={content}
      stepOrder={stepOrder}
      totalSteps={totalSteps}
      onContinue={onContinue}
      onBack={goBack}
    />
  );
};

export default IntroPartnerQuizStep;
