import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core';
import styles from './ProgressBar.module.scss';

const CustomLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
  },
  root: {
    borderRadius: 15,
  },
  bar: {
    borderRadius: 15,
    background: '#FFF',
  },
})(LinearProgress);

export type ProgressBarProps = {
  step: number,
  totalSteps: number,
}

const ProgressBar = ({ step, totalSteps }: ProgressBarProps) => {
  const progress = (step * 100) / totalSteps;

  return (
    <CustomLinearProgress className={styles.progress} variant="determinate" value={progress} />
  );
};

export default ProgressBar;
