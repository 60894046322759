import React, {
  useCallback, useContext, useEffect, useMemo,
} from 'react';
import { QuizData, QuizStepData } from 'src/types';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { delay } from 'src/utils/delay';
import { getUserPreferences, updateUserPreferences as updatePreferences } from 'src/redux/user';
import useServices from 'src/hooks/useServices';

export type QuizContextData = {
  getStepData: (id: string) => QuizStepData | undefined;
  userPreferences: Record<string, unknown>,
  updateUserPreferences: (preference: string, preferenceData: unknown) => void;
  trackAnalyticForStep: (id: string) => void;
  next: (id: string) => Promise<void>;
  back: (id: string) => Promise<void>;
}

const QuizContext = React.createContext<QuizContextData>(undefined!);

export type QuizProviderProps = {
  children?: React.ReactNode;
  data: QuizData;
};

export const QuizProvider = ({ data, children }: QuizProviderProps) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { analyticsService } = useServices();

  const { baseUrl, steps, nextStage } = data;

  const userPreferences = useSelector(getUserPreferences);

  const quizStepsMap = useMemo(() => steps.reduce((acc: { [id: string]: QuizStepData }, step, index, arr) => {
    acc[step.id] = {
      ...step,
      stepOrder: index + 1,
      totalSteps: arr.length,
    };
    return acc;
  }, {}), [data]);

  const getStepData = (id: string) => quizStepsMap[id];

  const getNextStep = (id: string) => {
    const foundIndex = data.steps.findIndex((step) => step.id === id);
    return data.steps[foundIndex + 1];
  };

  const getStepForMoveBack = (id: string) => {
    const foundIndex = data.steps.findIndex((step) => step.id === id);
    const splicedSteps = data.steps.slice(0, foundIndex);
    const reversedSteps = splicedSteps.reverse();
    return reversedSteps.find((step) => !step.canNotBackToScreen);
  };

  const updateUserPreferences = (preference: string, preferenceData: unknown) => {
    dispatch(updatePreferences(preference, preferenceData));
  };

  const trackAnalyticForStep = (id: string) => {
    const step = getStepData(id);

    if (!step || !step.analytic || !step.analytic.baseEvent) return;

    analyticsService.trackQuizEvent(step.analytic.baseEvent);
  };

  const next = async (id: string) => {
    await delay(500);
    const nextStep = getNextStep(id);

    if (!nextStep) {
      history.push(nextStage);
      return;
    }

    history.push(`${baseUrl}/${nextStep.id}`);
  };

  const back = async (id: string) => {
    await delay(300);
    const prevStep = getStepForMoveBack(id);

    if (!prevStep) return;

    history.push(`${baseUrl}/${prevStep.id}`);
  };

  return (
    <QuizContext.Provider
      value={{
        trackAnalyticForStep,
        userPreferences,
        updateUserPreferences,
        getStepData,
        next,
        back,
      }}
    >
      {children}
    </QuizContext.Provider>
  );
};

export const useQuizStep = (id: string) => {
  const {
    back,
    trackAnalyticForStep,
    next,
    getStepData,
    updateUserPreferences,
    userPreferences,
  } = useContext(QuizContext);

  useEffect(() => {
    trackAnalyticForStep(id);
  }, [id]);

  const stepData = useMemo(() => getStepData(id), [id]);

  const backStep = useCallback(() => back(id), [id]);

  const nextStep = useCallback(() => next(id), [id]);

  return {
    back: backStep,
    next: nextStep,
    stepData,
    updateUserPreferences,
    userPreferences,
  };
};
