import React from 'react';
import EmailScreen from 'src/components/QuizScreens/EmailScreen/EmailScreen';
import { EmailStepData } from 'src/types';
import { useQuizStep } from 'src/context/QuizContext';

export type EmailQuizStepProps = {
  id: string;
};

const EmailQuizStep = ({ id }: EmailQuizStepProps) => {
  const {
    back, next, stepData, userPreferences, updateUserPreferences,
  } = useQuizStep(id);

  if (!stepData) {
    // TODO(Logger)
    // TODO add fallback
    console.warn(`Data for ${id} step not found`);
    return null;
  }

  const {
    content, totalSteps, stepOrder, dataKey,
  } = stepData as EmailStepData;

  const goBack = async () => back();

  const initialValue = typeof userPreferences[dataKey] === 'string' ? (userPreferences[dataKey] as string) : '';

  const handleSubmit = async (data: { email: string; emailConsent: boolean }) => {
    updateUserPreferences(dataKey, data.email);
    await next();
  };

  return (
    <EmailScreen
      content={content}
      onBack={goBack}
      stepOrder={stepOrder}
      totalSteps={totalSteps}
      initialValue={initialValue}
      onSubmit={handleSubmit}
    />
  );
};

export default EmailQuizStep;
