import { createAction, createReducer } from '@reduxjs/toolkit';
import { RootInitialState } from '../store/rootReducer';

export const updateUserPreferences = createAction('user/USER_PREFERENCES_UPDATE', (
  preferenceName: string,
  preferenceValue: any,
) => ({
  payload: {
    preferenceName,
    preferenceValue,
  },
}));

export const getUserPreferences = (state: RootInitialState) => state.userProfile;

export const getBirthdate = (state: RootInitialState) => state.userProfile.date;

export const getPartnerBirthdate = (state: RootInitialState) => state.userProfile.partnerDate;

export const getGender = (state: RootInitialState) => state.userProfile.gender;

export const getPartnerGender = (state: RootInitialState) => state.userProfile.partnerGender;

export const getEmail = (state: RootInitialState) => state.userProfile.email;

const initialState = {
  gender: '',
  date: '',
  place: [],
  location: '',
  time: '',
  name: '',
  partnerGender: '',
  partnerDate: '',
  partnerPlace: [],
  partnerLocation: '',
  partnerTime: '',
  email: '',
};

export const userReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(updateUserPreferences, (state, { payload }) => ({
      ...state,
      [payload.preferenceName]: payload.preferenceValue,
    }));
});
