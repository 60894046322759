import React from 'react';
import { GenderStepData } from 'src/types';
import GenderChooseScreen from 'src/components/QuizScreens/GenderChooseScreen';
import { useQuizStep } from 'src/context/QuizContext';

export type GenderQuizStepProps = {
  id: string;
};

const GenderQuizStep = ({
  id,
}: GenderQuizStepProps) => {
  const {
    back,
    next,
    stepData,
    updateUserPreferences,
  } = useQuizStep(id);

  if (!stepData) {
    // TODO(Logger)
    // TODO add fallback
    console.warn(`Data for ${id} step not found`);
    return null;
  }

  const {
    totalSteps,
    stepOrder,
    dataKey,
    content,
  } = stepData as GenderStepData;

  const goBack = async () => back();

  const onChosen = async (choice: string) => {
    updateUserPreferences(dataKey, choice);
    await next();
  };

  return (
    <GenderChooseScreen
      content={content}
      onChosen={onChosen}
      stepOrder={stepOrder}
      totalSteps={totalSteps}
      onBack={goBack}
    />
  );
};

export default GenderQuizStep;
