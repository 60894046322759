import React from 'react';
import styles from './Select.module.scss';
import { ISelect } from './ISelect';

const Select: React.FC<ISelect> = ({
  name, data, value, onChange,
}) => (
  <div className={styles.selectWrap}>
    <select className={styles.select} name={name} id={name} value={value} onChange={onChange}>
      {data.map((item) => (
        <option value={item} className={styles.item} key={item}>
          {item}
        </option>
      ))}
    </select>
  </div>
);

export default Select;
