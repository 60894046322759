/* eslint-disable class-methods-use-this */
import Cookies from 'js-cookie';

export class CookiesService {
  private consentKey = 'CookieConsent';

  isPrivateDataCollectionAgreed(): boolean {
    return true;
  }

  agreePrivateDataCollection(): void {
    Cookies.set(this.consentKey, 'true', { expires: 365 });
  }
}
