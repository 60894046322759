import geoLocationAutocomplete from './constants';
import { IGeoLocationList, TGeolocationAction } from './types';

const initialState = {
  error: false,
  autoCompleteList: null as null | IGeoLocationList[],
};

type TInitialState = typeof initialState;

export const geoLocationAutocompleteReducer = (
  state = initialState,
  action: TGeolocationAction,
): TInitialState => {
  switch (action.type) {
    case geoLocationAutocomplete.SUCCESS:
      return {
        ...state,
        autoCompleteList: action.autoCompleteList,
      };
    case geoLocationAutocomplete.RESET:
      return {
        error: false,
        autoCompleteList: null,
      };
    case geoLocationAutocomplete.FAILURE:
      return {
        error: true,
        autoCompleteList: null,
      };
    default:
      return state;
  }
};
