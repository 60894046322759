import { QuizData, StepTypes } from 'src/types';
import { BASE_ROUTES } from 'src/constants/routes';
import introIllustration from 'src/assets/images/virgo/virgo.svg';
import genderIllustration from 'src/assets/images/virgo/genders.svg';
import achievedIllustration from 'src/assets/images/virgo/achieved.svg';
import ideaIllustration from 'src/assets/images/virgo/idea.svg';
import feelingsIllustration from 'src/assets/images/virgo/feelings.svg';
import { QUIZ_ANALYTIC_EVENTS } from 'src/services/analytics';

export const quizResources: QuizData = {
  baseUrl: BASE_ROUTES.QUIZ,
  nextStage: BASE_ROUTES.BILLING,
  steps: [
    {
      id: 'intro',
      screenType: StepTypes.INTRO,
      content: {
        title: `What zodiac signs get along with VIRGO? Discover your
          ideal partner and check your compatibility with others`,
        subtitle: `Take a short quiz and find out how you fare now.
          Get a full compatibility report with insights and advice.`,
        illustration: introIllustration,
      },
      analytic: {
        baseEvent: QUIZ_ANALYTIC_EVENTS.FLOW_START_OPEN,
      },
    },
    {
      id: 'gender',
      screenType: StepTypes.GENDER,
      content: {
        title: 'You usually describe yourself as',
        subtitle: 'This will make your report more personalized and accurate',
        illustration: genderIllustration,
        shouldShowNonBinary: true,
      },
      dataKey: 'gender',
      analytic: {
        baseEvent: QUIZ_ANALYTIC_EVENTS.FLOW_GENDER_OPEN,
      },
    },
    {
      id: 'achieved',
      screenType: StepTypes.SINGLE_ANSWER,
      content: {
        answers: [
          { text: 'Strongly agree', emoji: 'slightly_smiling_face' },
          { text: 'Somewhat disagree', emoji: 'thinking_face' },
          { text: 'Disagree', emoji: 'face_with_hand_over_mouth' },
        ],
        title: 'Are you modest about what you have achieved?',
        illustration: achievedIllustration,
      },
      dataKey: 'achieved',
    },
    {
      id: 'idea',
      screenType: StepTypes.SINGLE_ANSWER,
      content: {
        answers: [
          { text: 'Totally yes', emoji: 'slightly_smiling_face' },
          { text: 'Maybe, sometimes', emoji: 'thinking_face' },
          { text: 'Not really', emoji: 'wink' },
        ],
        title: 'Do you usually have highly original ideas?',
        illustration: ideaIllustration,
      },
      dataKey: 'idea',
    },
    {
      id: 'feelings',
      screenType: StepTypes.SINGLE_ANSWER,
      content: {
        answers: [
          { text: 'Totally yes', emoji: 'slightly_smiling_face' },
          { text: 'Maybe, sometimes', emoji: 'thinking_face' },
          { text: 'Not really', emoji: 'wink' },
        ],
        title: 'Do you tend to conceal your feelings?',
        illustration: feelingsIllustration,
      },
      dataKey: 'feelings',
    },
    {
      id: 'email',
      screenType: StepTypes.EMAIL,
      content: {
        title: 'What is your email address?',
        subtitle: 'Enter email address to get access to personalized love reading and discover '
          + 'relationship experts you were matched with',
        illustration: ideaIllustration,
        tip: 'We respect your privacy and are committed to protecting your personal data',
      },
      dataKey: 'email',
    },
    {
      id: 'processing',
      screenType: StepTypes.PROCESSING,
      content: {
        title: 'Hold on a little while we are working on your precisely designed compatibility report',
        stages: [
          'Analysing your answers...',
          'Designing your natal chart...',
          'Checking your compatibility with other signs...',
          'Creating your astrology profile...',
          'Finalizing your report...',
        ],
      },
      canNotBackToScreen: true,
    },
  ],
};
