import React from 'react';
import styles from './ProcessingScreenLoaderCircle.module.scss';

export type ProcessingScreenLoaderCircleProps = {
  value: number;
}

const ProcessingScreenLoaderCircle = ({ value }: ProcessingScreenLoaderCircleProps) => (
  <svg viewBox="0 0 35 35" className={styles.circle}>
    <circle
      className="donut-hole"
      cx="50%"
      cy="50%"
      r="15.91549430918954"
      fill="transparent"
    >
    </circle>
    <circle
      className="donut-ring"
      cx="50%"
      cy="50%"
      r="15.91549430918954"
      strokeOpacity="0.3"
      fill="transparent"
      stroke="#E4832D"
      strokeWidth="2"
    >
    </circle>
    <circle
      className="donut-segment"
      cx="50%"
      cy="50%"
      r="15.91549430918954"
      fill="transparent"
      strokeOpacity="1"
      stroke="#E4822D"
      strokeWidth="2"
      strokeDasharray={`${value} ${100 - value}`}
      strokeDashoffset={`${value}`}
    >
    </circle>
  </svg>
);

export default ProcessingScreenLoaderCircle;
