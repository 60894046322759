import React from 'react';
import styles from './PlaceScreenCompleteItem.module.scss';

export interface PlaceScreenCompleteItemProps {
  country: string;
  region: string;
  city: string;
  coordinates: number[];
  onClick: (coordinates: string[], value: string) => void;
}

const PlaceScreenCompleteItem = ({
  country,
  region,
  city,
  onClick,
  coordinates,
}: PlaceScreenCompleteItemProps) => {
  const handleClick = () => {
    const cityName = city ? `${city}, ` : '';
    const regionName = region ? `${region}, ` : '';
    const countryName = country || '';
    const location = `${cityName}${regionName}${countryName}`;
    const stringCoordinates = coordinates.map((number) => number.toString());
    onClick(stringCoordinates, location);
  };

  return (
    <div className={styles.item} onClick={handleClick}>
      <p className={styles.city}>{city}</p>
      <p className={styles.region}>
        {region && `${region}, `}
        <span>{country}</span>
      </p>
    </div>
  );
};

export default PlaceScreenCompleteItem;
