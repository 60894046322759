/* eslint-disable arrow-body-style */
import React, { useState } from 'react';
import cn from 'classnames';

import { isJpg, isPng, isWebp } from 'src/utils/images';
import dimensions from 'src/assets/scss/vars.module.scss';
import styles from './ImagedPageSection.module.scss';

export enum DPIScales {
  DEFAULT = '1x',
  TWICE = '2x'
}

type BreakpointSourcesMap = {
  url: string,
  scale?: DPIScales,
}

export type ImagedPageSectionProps = {
  className?: string;
  holderClassName?: string;
  imageClassName?: string;
  children?: React.ReactNode;
  originalUrl: string,
  sources?: Partial<{
    desktop: Array<BreakpointSourcesMap>,
    tablet: Array<BreakpointSourcesMap>,
    mobile: Array<BreakpointSourcesMap>
  }>
};

const ImagedPageSection = (props: ImagedPageSectionProps) => {
  const {
    className,
    children,
    imageClassName,
    holderClassName,
    originalUrl,
    sources = {},
  } = props;

  const [isModernTypesNotSupported, setIsModernTypesNotSupported] = useState(false);

  const desktopSources = Array.isArray(sources.desktop) ? sources.desktop : [];
  const tabletSources = Array.isArray(sources.tablet) ? sources.tablet : [];
  const mobileSources = Array.isArray(sources.mobile) ? sources.mobile : [];

  const makeSrcset = (sourceMap: Array<BreakpointSourcesMap>) => sourceMap.map((extData) => {
    return extData.scale ? `${extData.url} ${extData.scale}` : extData.url;
  }).join(', ');

  const buildSources = (sourceMap: Array<BreakpointSourcesMap>) => {
    const { webp, png, jpg } = sourceMap.reduce((acc: {
      webp: Array<BreakpointSourcesMap>,
      jpg: Array<BreakpointSourcesMap>,
      png: Array<BreakpointSourcesMap>,
    }, source) => {
      if (isWebp(source.url)) {
        acc.webp.push(source);
      } else if (isJpg(source.url)) {
        acc.jpg.push(source);
      } else if (isPng(source.url)) {
        acc.png.push(source);
      }
      return acc;
    }, { webp: [], jpg: [], png: [] });

    return (
      [
        webp.length && !isModernTypesNotSupported ? { srcSet: makeSrcset(webp), memeType: 'image/webp' } : false,
        jpg.length ? { srcSet: makeSrcset(jpg), memeType: 'image/jpeg' } : false,
        png.length ? { srcSet: makeSrcset(png), memeType: 'image/png' } : false,
      ].filter(Boolean)
    ) as Array<{ srcSet: string; memeType: string }>;
  };

  return (
    <div className={cn(styles.root, className)}>
      <div className={cn(styles.holder, holderClassName)} />
      <picture>
        { mobileSources.length > 0 && buildSources(mobileSources).map(({ srcSet, memeType }) => (
          <source
            key={srcSet}
            type={memeType}
            media={`(max-width: ${dimensions.maximalMobileWidth})`}
            srcSet={srcSet}
          />
        ))}
        { tabletSources.length > 0 && buildSources(tabletSources).map(({ srcSet, memeType }) => (
          <source
            key={srcSet}
            type={memeType}
            media={`(max-width: ${dimensions.maximalTabletWidth})`}
            srcSet={srcSet}
          />
        ))}
        { desktopSources.length > 0 && buildSources(desktopSources).map(({ srcSet, memeType }) => (
          <source key={srcSet} type={memeType} srcSet={srcSet} />
        ))}
        <img
          onError={() => setIsModernTypesNotSupported(true)}
          src={originalUrl}
          alt="section-content"
          className={cn(styles.image, imageClassName)}
        />
      </picture>
      { children }
    </div>
  );
};

export default ImagedPageSection;
