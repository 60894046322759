import React from 'react';
import ProcessingScreen from 'src/components/QuizScreens/ProcessingScreen';
import { ProcessingStepData } from 'src/types';
import { useQuizStep } from 'src/context/QuizContext';

export type ProcessingQuizStepProps = {
  id: string;
};

const ProcessingQuizStep = ({ id }: ProcessingQuizStepProps) => {
  const { next, stepData } = useQuizStep(id);

  if (!stepData) {
    // TODO(Logger)
    // TODO add fallback
    console.warn(`Data for ${id} step not found`);
    return null;
  }

  const {
    totalSteps,
    stepOrder,
    content,
    // duration,
  } = stepData as ProcessingStepData;

  const onProcessingFinished = async () => next();

  return (
    <ProcessingScreen
      totalSteps={totalSteps}
      stepOrder={stepOrder}
      content={content}
      onFinished={onProcessingFinished}
    />
  );
};

export default ProcessingQuizStep;
