import React, { ChangeEvent, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from 'src/components/common/Button/Button';
import Input from 'src/components/common/Input/Input';
import {
  fetchAutocompletePlaces,
  geolocationAutocompleteReset,
} from 'src/redux/geoLocationAutocomplete/actions';
import { debounce } from 'src/utils/debounce';
import QuizScreenLayout from 'src/components/layout/QuizScreenLayout';
import { PlaceStepData } from 'src/types';
import PlaceScreenCompleteList from './PlaceScreenCompleteList';
import styles from './PlaceScreen.module.scss';

export type PlaceScreenProps = {
  onBack: () => void;
  onChosen: (coordinates: string[], location: string) => void;
  initialCoordinates?: string[];
  initialLocation?: string;
} & Omit<PlaceStepData, 'placeDataKey' | 'locationDataKey' | 'id' | 'screenType' | 'analytic'>

const PlaceScreen = ({
  content,
  initialCoordinates = [],
  initialLocation = '',
  stepOrder,
  totalSteps,
  onBack,
  onChosen,
}: PlaceScreenProps) => {
  const [location, setLocation] = useState(initialLocation);
  const [coordinates, setCoordinates] = useState<string[]>(initialCoordinates);

  const dispatch = useDispatch();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setLocation(value);
    debouncedGeolocation(value);
  };

  const debouncedGeolocation = useCallback(
    debounce((value) => {
      // eslint-disable-next-line no-unused-expressions
      value ? dispatch(fetchAutocompletePlaces(value)) : dispatch(geolocationAutocompleteReset());
    }, 500),
    [],
  );

  const handleClick = useCallback((newCoordinates, newLocation) => {
    setLocation(newLocation);
    setCoordinates(newCoordinates);
  }, []);

  const isDisabled = !location || !coordinates.length;

  const handleButtonClick = () => {
    if (isDisabled) return;

    onChosen(coordinates, location);
  };

  return (
    <QuizScreenLayout
      title={content.title}
      illustration={content.illustration}
      stepOrder={stepOrder}
      totalSteps={totalSteps}
      onBack={onBack}
    >
      <div>
        <p className={styles.text}>{content.subtitle}</p>
        <div className={styles.autocomplete}>
          <Input
            placeholder="Enter city of birth"
            value={location}
            onChange={handleChange}
          />
          <PlaceScreenCompleteList onSelected={handleClick} />
        </div>
      </div>
      <Button onClick={handleButtonClick} disabled={isDisabled}>
        Continue
      </Button>
    </QuizScreenLayout>
  );
};

export default PlaceScreen;
