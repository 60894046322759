/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useFormik } from 'formik';
import Input from 'src/components/common/Input/Input';
import Button from 'src/components/common/Button';
import QuizScreenLayout from 'src/components/layout/QuizScreenLayout';
import { NameStepData } from 'src/types';
import { validationSchema } from './validationSchema';
import styles from './NameScreen.module.scss';

export type NameScreenProps = {
  initialValue?: string;
  onSubmit: (name: string) => void;
  onBack: () => void;
} & Omit<NameStepData, 'dataKey' | 'id' | 'screenType' | 'analytic'>;

const NameScreen = (props: NameScreenProps) => {
  const {
    content,
    totalSteps,
    stepOrder,
    initialValue = '',
    onSubmit,
    onBack,
  } = props;

  const {
    errors,
    touched,
    getFieldProps,
    handleSubmit,
  } = useFormik({
    initialValues: {
      name: initialValue,
    },
    validationSchema,
    onSubmit: ((values) => {
      onSubmit(values.name);
    }),
  });

  return (
    <QuizScreenLayout
      title={content.title}
      onBack={onBack}
      illustration={content.illustration}
      stepOrder={stepOrder}
      totalSteps={totalSteps}
    >
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.container}>
          <p className={styles.text}>{content.subtitle}</p>
          <Input
            {...getFieldProps('name')}
            error={touched.name && !!errors.name}
            errorText={errors.name}
            className={styles.input}
            placeholder="Enter your display name"
          />
        </div>
        <Button type="submit">
          Continue
        </Button>
      </form>
    </QuizScreenLayout>
  );
};

export default NameScreen;
