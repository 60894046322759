import React, { FC } from 'react';
import cn from 'classnames';
import ImagedPageSection, { DPIScales } from 'src/components/common/ImagedPageSection';
import pngXl from 'src/assets/images/billing1/questions/questions-xl.png';
import pngXl2x from 'src/assets/images/billing1/questions/questions-xl-2x.png';
import webpXl from 'src/assets/images/billing1/questions/questions-xl.webp';
import webpXl2x from 'src/assets/images/billing1/questions/questions-xl-2x.webp';
import pngMd from 'src/assets/images/billing1/questions/questions-md.png';
import pngMd2x from 'src/assets/images/billing1/questions/questions-md-2x.png';
import webpMd from 'src/assets/images/billing1/questions/questions-md.webp';
import webpMd2x from 'src/assets/images/billing1/questions/questions-md-2x.webp';
import pngXs from 'src/assets/images/billing1/questions/questions-xs.png';
import pngXs2x from 'src/assets/images/billing1/questions/questions-xs-2x.png';
import webpXs from 'src/assets/images/billing1/questions/questions-xs.webp';
import webpXs2x from 'src/assets/images/billing1/questions/questions-xs-2x.webp';
import Button from 'src/components/common/Button/Button';
import styles from './SectionQuestions.module.scss';
import commonStyles from '../common.module.scss';

const sectionData = {
  originalUrl: pngXl,
  sources: {
    desktop: [
      { url: webpXl },
      { url: webpXl2x, scale: DPIScales.TWICE },
      { url: pngXl },
      { url: pngXl2x, scale: DPIScales.TWICE },
    ],
    tablet: [
      { url: webpMd },
      { url: webpMd2x, scale: DPIScales.TWICE },
      { url: pngMd },
      { url: pngMd2x, scale: DPIScales.TWICE },
    ],
    mobile: [
      { url: webpXs },
      { url: webpXs2x, scale: DPIScales.TWICE },
      { url: pngXs },
      { url: pngXs2x, scale: DPIScales.TWICE },
    ],
  },
};

interface Props {
  onGetReport: () => void;
  isAgree: boolean;
}

const SectionQuestions: FC<Props> = ({ onGetReport, isAgree }) => (
  <ImagedPageSection
    originalUrl={sectionData.originalUrl}
    sources={sectionData.sources}
    holderClassName={styles.holder}
  >
    <div className={cn(commonStyles.sectionButtonWrapper, styles.buttonWrapper)}>
      <Button
        disabled={!isAgree}
        wrapperClassName={commonStyles.sectionButtonContainer}
        className={commonStyles.sectionButton}
        onClick={onGetReport}
      >
        Get my report
      </Button>
    </div>
  </ImagedPageSection>
);

export default SectionQuestions;
