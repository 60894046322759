import React from 'react';
import DefaultLayout from 'src/components/layout/DefaultLayout';
import Button from 'src/components/common/Button/Button';
import StoreLink from 'src/components/PostQuiz/StoreLink';
import AppStore from 'src/assets/images/appstore.png';
import GooglePlay from 'src/assets/images/googleplay.png';
import Copyright from '../../components/common/Copyright';
import styles from './Success.module.scss';

const Success = () => (
  <DefaultLayout>
    <div className={styles.container}>
      <h3 className={styles.title}>Success!</h3>
      <p className={styles.text}>Please download your report and install the app</p>
      <a
        href="assets/Virgo.pdf"
        download
      >
        <Button
          className={styles.button}
          wrapperClassName={styles.button_wrapper}
        >
          Download PDF report
        </Button>
      </a>
      <div className={styles.store_link_container}>
        <StoreLink
          src={AppStore}
          wrapperClassName={styles.store_link}
          href="https://apps.apple.com/us/app/nebula-horoscope-astrology/id1459969523"
        />
        <StoreLink
          src={GooglePlay}
          wrapperClassName={styles.store_link}
          href="https://play.google.com/store/apps/details?id=genesis.nebula&hl=en"
        />
      </div>
      <Copyright className={styles.copyright} />
    </div>
  </DefaultLayout>
);

export default Success;
