export class GoogleAnalyticsService {
  private static track(...args: unknown[]) {
    // @ts-ignore
    const { gtag } = window;
    if (gtag) {
      gtag(...args);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  paymentSuccess({ price, orderId, productId }: { price: number; orderId: string; productId: string }) {
    GoogleAnalyticsService.track('event', 'purchase', {
      transaction_id: orderId,
      value: price,
      currency: 'USD',
      items: [{
        id: productId,
      }],
    });
  }
}
