import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { geolocationAutocompleteReset } from 'src/redux/geoLocationAutocomplete/actions';
import { RootInitialState } from 'src/redux/store/rootReducer';
import styles from './AutoCompleteList.module.scss';
import PlaceScreenCompleteItem from '../PlaceScreenCompleteItem';

export type PlaceScreenCompleteListProps = {
  onSelected: (coordinates: string[], location: string) => void
}

const AutoCompleteList = ({ onSelected }: PlaceScreenCompleteListProps) => {
  const dispatch = useDispatch();
  const { autoCompleteList } = useSelector((state: RootInitialState) => state.geoLocation);

  const handleClick = (coordinates: string[], value: string) => {
    onSelected(coordinates, value);
    dispatch(geolocationAutocompleteReset());
  };

  if (!autoCompleteList) {
    return null;
  }

  return (
    <div className={styles.list}>
      {autoCompleteList.map((item) => (
        <PlaceScreenCompleteItem
          key={item.properties.id}
          coordinates={item.geometry.coordinates}
          country={item.properties.country}
          region={item.properties.region}
          city={item.properties.name}
          onClick={handleClick}
        />
      ))}
    </div>
  );
};

export default AutoCompleteList;
