import React from 'react';
import TestimonialScreen from 'src/components/QuizScreens/TestimonialScreen';
import { TestimonialStepData } from 'src/types';
import { useQuizStep } from 'src/context/QuizContext';

export type TestimonialQuizStepProps = {
  id: string;
};

const TestimonialQuizStep = ({ id }: TestimonialQuizStepProps) => {
  const { back, next, stepData } = useQuizStep(id);

  if (!stepData) {
    // TODO(Logger)
    // TODO add fallback
    console.warn(`Data for ${id} step not found`);
    return null;
  }

  const {
    content,
    totalSteps,
    stepOrder,
  } = stepData as TestimonialStepData;

  const goBack = async () => back();

  const onContinue = async () => next();

  return (
    <TestimonialScreen
      content={content}
      totalSteps={totalSteps}
      stepOrder={stepOrder}
      onContinue={onContinue}
      onBack={goBack}
    />
  );
};

export default TestimonialQuizStep;
