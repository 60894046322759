import QuizScreenLayout from 'src/components/layout/QuizScreenLayout';
import React, { useState } from 'react';
import { MultiAnswerStepData } from 'src/types';
import AnswersList from 'src/components/common/AnswersList';
import Button from 'src/components/common/Button';
import styles from './MultipleAnswerScreen.module.scss';

export type MultipleAnswerScreenProps = {
  onBack: () => void;
  onChosen: (chooses: Array<string>) => void;
  initialValue?: Array<string>
} & Omit<MultiAnswerStepData, 'dataKey' | 'id' | 'screenType' | 'analytic'>

const MultipleAnswerScreen = (props: MultipleAnswerScreenProps) => {
  const {
    content,
    onChosen,
    initialValue = [],
    onBack,
    stepOrder,
    totalSteps,
  } = props;

  const [selectedAnswers, setSelectedAnswers] = useState(initialValue);

  const onAnswered = (choice: string) => {
    if (selectedAnswers.includes(choice)) {
      setSelectedAnswers((value) => value.filter((item) => item !== choice));
    } else {
      setSelectedAnswers((value) => [...value, choice]);
    }
  };

  return (
    <QuizScreenLayout
      title={content.title}
      illustration={content.illustration}
      stepOrder={stepOrder}
      totalSteps={totalSteps}
      onBack={onBack}
    >
      <div className={styles.container}>
        <p className={styles.text}>Choose all that apply</p>
        <AnswersList
          className={styles.answersList}
          onChosen={onAnswered}
          answers={content.answers}
          selectedAnswers={selectedAnswers}
        />
        <div className={styles.buttonWrapper}>
          <Button
            onClick={() => onChosen(selectedAnswers)}
            className={styles.button}
            wrapperClassName={styles.buttonContainer}
          >
            Continue
          </Button>
        </div>
      </div>
    </QuizScreenLayout>
  );
};

export default MultipleAnswerScreen;
