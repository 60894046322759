export enum PackageId {
    INTRO_STANDARD = 'intro_standard',
    INTRO_PREMIUM = 'intro_premium',
    INTRO_PREMIUM_PLUS = 'intro_premium_plus',
    BASIC = 'regular_basic',
    STANDART = 'regular_standard',
    PREMIUM = 'regular_premium',
    COSMIC = 'regular_cosmic'
}

export interface Package {
    id: PackageId;
    label: string;
    display_price: string;
    display_discount: string | null;
    display_features: string[];
    charge_amount: number;
    top_up_amount: number;
    sort_order: number;
    is_special: boolean;
}
