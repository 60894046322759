import React from 'react';
import ButtonBack from 'src/components/common/ButtonBack/ButtonBack';
import { IntroPartnerStepData } from 'src/types';
import Button from 'src/components/common/Button';
import ScreenIllustration from 'src/components/common/ScreenIllustration';
import styles from './IntroPartnerScreen.module.scss';

export type IntroPartnerScreenProps = {
  onContinue: () => void;
  onBack: () => void;
} & Omit<IntroPartnerStepData, 'id' | 'screenType' | 'analytic'>;

const IntroPartnerQuizStep = ({
  onBack,
  onContinue,
  content,
}: IntroPartnerScreenProps) => (
  <div className={styles.container}>
    <div className={styles.backWrapper}>
      <ButtonBack secondary onClick={onBack} />
    </div>
    <h1 className={styles.title}>{content.title}</h1>
    <div className={styles.imageWrapper}>
      <ScreenIllustration src={content.illustration} />
    </div>
    <Button wrapperClassName={styles.buttonWrapper} className={styles.button} onClick={onContinue}>Continue</Button>
  </div>
);

export default IntroPartnerQuizStep;
