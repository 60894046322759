import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchLocation } from 'src/location/store';

export const useLocationComponent = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLocation());
  }, [dispatch]);
};
