import React from 'react';
import { InformationalStepData } from 'src/types';
import ButtonBack from 'src/components/common/ButtonBack';
import Button from 'src/components/common/Button';
import { useMarkdown } from 'src/hooks/useMarkdown';
import styles from './InformationalScreen.module.scss';

export type InformationalScreenProps = {
  onContinue: () => void;
  onBack: () => void;
} & Omit<InformationalStepData, 'id' | 'screenType' | 'analytic'>;

const InformationalScreen = ({
  onBack,
  onContinue,
  content,
}: InformationalScreenProps) => {
  const parsedDescription = useMarkdown(content.text);

  return (
    <div className={styles.wrapper}>
      <div className={styles.background} />
      <div className={styles.backWrapper}>
        <ButtonBack onClick={onBack} className={styles.back} />
      </div>
      <div className={styles.content}>
        <div className={styles.preview}>
          <img src={content.illustration} alt="venus" className={styles.illustration} />
          <div className={styles.previewContent}>
            <h2 className={styles.title}>
              {content.title}
            </h2>
          </div>
        </div>
        <div
          className={styles.descriptionWrapper}
          dangerouslySetInnerHTML={{ __html: parsedDescription }}
        />
        <Button
          wrapperClassName={styles.buttonWrapper}
          className={styles.button}
          onClick={onContinue}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default InformationalScreen;
