import React from 'react';
import QuizScreenLayout from 'src/components/layout/QuizScreenLayout';
import { SingleAnswerStepData } from 'src/types';
import AnswersList from 'src/components/common/AnswersList';

export type SingleAnswerScreenProps = {
  onBack: () => void;
  onChosen: (choose: string) => void;
} & Omit<SingleAnswerStepData, 'screenType' | 'dataKey' | 'id' | 'analytic'>;

const SingleAnswerScreen = ({
  content,
  onBack,
  stepOrder,
  onChosen,
  totalSteps,
}: SingleAnswerScreenProps) => (
  <QuizScreenLayout
    title={content.title}
    illustration={content.illustration}
    stepOrder={stepOrder}
    totalSteps={totalSteps}
    radio
    onBack={onBack}
  >
    <AnswersList answers={content.answers} onChosen={onChosen} />
  </QuizScreenLayout>
);

export default SingleAnswerScreen;
