import React from 'react';
import cn from 'classnames';
import styles from './Checkbox.module.scss';

export type CheckboxProps = {
  main?: boolean;
  name: string;
  label: React.ReactElement | string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
};

const Checkbox = ({
  name, label, checked, onChange, main, className,
}: CheckboxProps) => (
  <>
    <input
      type="checkbox"
      id={name}
      name={name}
      className={cn(main ? styles.main : styles.input, className)}
      checked={checked}
      onChange={onChange}
    />
    <label htmlFor={name}>{label}</label>
  </>
);

export default React.memo(Checkbox);
