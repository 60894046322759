import React from 'react';
import cn from 'classnames';
import Emoji from 'src/components/common/Emoji';
import styles from './AnswersList.module.scss';

export type AnswerListChoice = {
  text: string;
  emoji?: string;
}

export type AnswerListProps = {
  className?: string;
  selectedAnswers?: Array<string>;
  onChosen: (choose: string) => void;
  answers: Array<AnswerListChoice>
}

const AnswersList = (props: AnswerListProps) => {
  const {
    onChosen,
    answers,
    className,
    selectedAnswers = [],
  } = props;

  const getChoiceClasses = (choice: AnswerListChoice) => cn({
    [styles.choice]: true,
    [styles.choiceActive]: selectedAnswers.includes(choice.text),
  });

  return (
    <div className={cn(styles.container, className)}>
      <ul className={styles.choiceList}>
        {answers.map((choice) => (
          <li className={styles.choiceListItem} key={choice.text}>
            <div
              role="button"
              onClick={() => onChosen(choice.text)}
              className={getChoiceClasses(choice)}
            >
              <span className={styles.choiceText}>
                {choice.text}
              </span>
              { choice.emoji && (
                <div className={styles.choiceEmojiWrapper}>
                  <Emoji emoji={choice.emoji} />
                </div>
              ) }
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AnswersList;
