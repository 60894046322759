import { useEffect, useState } from 'react';
import remark from 'remark';
import remarkHtml from 'remark-html';

export function useMarkdown(content: string) {
  const [parsedContent, setParsedContent] = useState<string>('');

  useEffect(() => {
    remark()
      .use(remarkHtml)
      .process(content)
      .then((res) => setParsedContent(res.toString()));
  }, [content]);

  return parsedContent;
}
