import english from 'src/assets/images/languages/english.svg';
import astrologer from './constants';
import { TAstrologerAction } from './types';

const initialState = {
  info: {
    id: '94ab9b05-41f5-4549-8253-30c15b65c876',
    name: 'Yana',
    focus: 'Love & Relationship Expert',
    description:
      // eslint-disable-next-line max-len
      'I have been passionate about astrology for over 20 years now. I have used it to find myself and significantly improve the quality of my life. Gradually, it became more than a passion - it became my calling, my vocation. Now I use it to help others in difficult life situations, to provide psychological support and also informed guidance which helps my clients to make decisions about some of the most important things in their lives. I am continuously widening and deepening my knowledge and improving my skills, as there is no limit to perfection. In astro-psychology I follow the evolutionary (spiritual, soulful) school of thought. At the same time, I am well-practiced in the predictive astrological methods which provide a valuable insight into the future possibilities and allow you to take the best path when solving your problems.',
    feedback_count: 505,
    image:
      'https://astrocrmmedia.obrio.net/photos/development/image_94ab9b05-41f5-4549-8253-30c15b65c876.jpg',
    languages: [
      { image: english, label: 'English' },
    ],
    rating: 5,
    specializations: ['Predictions', 'Natal charts Reading', 'Personality', 'Love & Relationship'],
  },
};

type TInitialState = typeof initialState;

export const astrologerReducer = (
  state = initialState,
  action: TAstrologerAction,
): TInitialState => {
  switch (action.type) {
    case astrologer.SUCCESS:
      return {
        ...state,
        info: action.info,
      };
    case astrologer.FAKE:
      return {
        ...state,
        info: initialState.info,
      };
    default:
      return state;
  }
};

export default astrologerReducer;
