export * from './routes';

export function getCookiePolicyPagePath() {
  return process.env.REACT_APP_COOKIE_POLICY_PAGE ?? '/cookie-policy';
}

export function getTermsPagePath() {
  return '/flow/terms';
}

export function getPrivacyPolicyPagePath() {
  return '/flow/terms#privacy-policy';
}

export function getSubscriptionPolicyPagePath() {
  return '/flow/terms#subscription-policy';
}

export function getPaymentButtonPath() {
  return 'payment';
}

export function getFAQPagePath() {
  return process.env.REACT_APP_FAQ_PAGE ?? '/faq';
}

export function getContactUsPagePath() {
  return process.env.REACT_APP_CONTACT_US_PAGE ?? '/contact-us';
}

export function getPaymentTermsPagePath() {
  return process.env.REACT_APP_PAYMENT_TERMS_PAGE ?? '/payment-terms';
}

export function getThankYouIntroPackagesPath(orderId: string) {
  return process.env.REACT_APP_THANK_YOU_INTRO_PACKAGES ?? `/thank-you/intro-packages?order_id=${orderId}`;
}

export function getThankYouReportPath() {
  return process.env.REACT_APP_THANK_YOU_INTRO_PACKAGES ?? '/thank-you/report';
}
