/* eslint-disable class-methods-use-this */
import qs from 'qs';
import { AmplitudeService } from './AmplitudeService';
import { GoogleAnalyticsService } from './GoogleAnalyticsService';
import { QUIZ_ANALYTIC_EVENTS } from './events';

export interface UtmTags {
  utm_source?: string,
  utm_medium?: string,
  utm_campaign?: string,
  utm_content?: string,
  utm_term?: string,
}

export class AnalyticsService {
  private amplitudeService: AmplitudeService;

  private googleAnalyticsService: GoogleAnalyticsService;

  private utmTags: UtmTags;

  constructor(amplitudeService: AmplitudeService, googleAnalyticsService: GoogleAnalyticsService) {
    this.utmTags = this.loadUtmTags();
    this.saveUtmTagsToStorage(this.utmTags);

    this.amplitudeService = amplitudeService;
    this.googleAnalyticsService = googleAnalyticsService;

    if (this.utmTags.utm_source) {
      this.amplitudeService.setUserProperties({
        media_source: this.utmTags.utm_source,
      });
    }
  }

  trackQuizEvent(quizEvent: QUIZ_ANALYTIC_EVENTS) {
    this.amplitudeService.trackQuizEvent(quizEvent);
  }

  launch() {
    this.amplitudeService.launch();
  }

  paymentCardChosen() {
    this.amplitudeService.paymentCardChosen();
  }

  paymentPaypalChosen() {
    this.amplitudeService.paymentPaypalChosen();
  }

  paymentError() {
    this.amplitudeService.paymentError();
  }

  paymentSuccess(paymentData: { productId: string, price: number; orderId: string }) {
    this.amplitudeService.paymentSuccess(paymentData);
    this.googleAnalyticsService.paymentSuccess(paymentData);
  }

  lpIntroOfferOpen() {
    this.amplitudeService.lpIntroOfferOpen();
  }

  lpIntroOfferStandardClick(offerData: { productId: string }) {
    this.amplitudeService.lpIntroOfferStandardClick(offerData);
  }

  lpIntroOfferGetStartedClick() {
    this.amplitudeService.lpIntroOfferGetStartedClick();
  }

  private loadUtmTags() {
    const utmTags = this.loadUtmTagsFromStorage();

    const parsedQs = qs.parse(window.location.search, { ignoreQueryPrefix: true }) as { [key: string]: unknown };

    if (parsedQs.utm_source) {
      utmTags.utm_source = Array.isArray(parsedQs.utm_source) ? parsedQs.utm_source[0] : parsedQs.utm_source;
    }
    if (parsedQs.utm_medium) {
      utmTags.utm_medium = Array.isArray(parsedQs.utm_medium) ? parsedQs.utm_medium[0] : parsedQs.utm_medium;
    }
    if (parsedQs.utm_campaign) {
      utmTags.utm_campaign = Array.isArray(parsedQs.utm_campaign) ? parsedQs.utm_campaign[0] : parsedQs.utm_campaign;
    }
    if (parsedQs.utm_content) {
      utmTags.utm_content = Array.isArray(parsedQs.utm_content) ? parsedQs.utm_content[0] : parsedQs.utm_content;
    }
    if (parsedQs.utm_term) {
      utmTags.utm_term = Array.isArray(parsedQs.utm_term) ? parsedQs.utm_term[0] : parsedQs.utm_term;
    }

    return utmTags;
  }

  private saveUtmTagsToStorage(utmTags: UtmTags) {
    try {
      window.sessionStorage.setItem('utmTags', JSON.stringify(utmTags));
    } catch (err) {
      // TODO(Logger)
      console.error('Failed to save utm tags', err);
    }
  }

  private loadUtmTagsFromStorage(): UtmTags {
    try {
      const utmTagsRaw = window.sessionStorage.getItem('utmTags') || '{}';
      return JSON.parse(utmTagsRaw);
    } catch (err) {
      // TODO(Logger)
      console.error('Failed to load utm tags', err);
      return {};
    }
  }
}
