import { Package } from 'src/types';
import { BaseService } from './BaseService';
import { ZodiacTypes } from '../types/zodiacs';

export type CardPaymentUrl = {
  merchant: string;
  paymentIntent: string;
  signature: string;
}

export type PurchaseDescription = {
  cardPaymentUrl: CardPaymentUrl;
  paypalPaymentUrl: string;
};

export enum PurchaseReportTypes {
  NUMEROLOGY_CALCULATOR = 'numerology_calculator',
  TAROT_SPREAD_FOR_2022 = 'tarot_spread_for_2022',
}

export enum PurchaseProductsTypes {
  PERSONALIZED_COMPATIBILITY_REPORT = 'personalized_compatibility_report',
  TAROT_SPREAD_FOR_2022 = 'tarot_spread_for_2022',
  NUMEROLOGY_CALCULATOR = 'numerology_calculator',
  ASK_NEBULA_WEEKLY_999 = 'asknebula_weekly_9.99',
  ASK_NEBULA_WEEKLY_499 = 'asknebula_weekly_4.99',
}

export class PurchaseService extends BaseService {
  async createMatchingPurchase(
    packageId: string,
    astrologerId: string,
  ): Promise<PurchaseDescription> {
    const data = await this.httpClient.post('/funnel/matching/purchase', {
      package_id: packageId,
      extra_data: {
        astrologer_id: astrologerId,
      },
    });

    return {
      cardPaymentUrl: data.formUrl,
      paypalPaymentUrl: data.scriptUrl,
    };
  }

  async purchaseCompatibilityReportAndSubscription(email: string): Promise<PurchaseDescription> {
    const orderData = await this.httpClient.post('/orders', {
      order_data: {
        subjects: [
          {
            assigned_to: 'partner_1',
            birth_place: {
              longitude: 101.6726903,
              place: 'Kuala Lumpur, KL, Malaysia',
              latitude: 3.135985,
            },
            gender: 'male',
            birth_date: '1976-04-23',
            birth_time: null,
          },
          {
            birth_place: {
              place: 'Kuala Lumpur, KL, Malaysia',
              latitude: 3.135985,
              longitude: 101.6726903,
            },
            birth_date: '1986-05-10',
            gender: 'female',
            assigned_to: 'partner_2',
            birth_time: '4:08',
          },
        ],
      },
      email,
      offer_name: 'subscription_USD_Premium_2w_19',
      project: 'Nebula',
    });

    await this.httpClient.post(`/orders/${orderData.order_id}`, {
      offer_name: 'subscription_USD_Premium_2w_19',
    });

    const paymentsData = await this.httpClient.get(`/orders/${orderData.order_id}/payments-new`);

    return {
      cardPaymentUrl: {
        merchant: paymentsData.merchant_data.merchant_id,
        paymentIntent: paymentsData.merchant_data.payment_intent,
        signature: paymentsData.merchant_data.signature,
      },
      paypalPaymentUrl: paymentsData.paypal_payment_script,
    };
  }

  async purchaseDiscountCompatibilityReportAndSubscription(
    reportInfo: Partial<{
      zodiacSign: ZodiacTypes;
      partnerZodiacSign: ZodiacTypes;
      gender: string;
      partnerGender: string;
    }> = {},
  ): Promise<PurchaseDescription> {
    const data = await this.httpClient.post(
      '/funnel/personalized-compatibility-report/purchase-discount',
      {
        extra_data: {
          zodiacSign1: reportInfo.zodiacSign,
          zodiacSign2: reportInfo.partnerZodiacSign,
          gender1: reportInfo.gender,
          gender2: reportInfo.partnerGender,
        },
      },
    );

    return {
      cardPaymentUrl: data.formUrl,
      paypalPaymentUrl: data.scriptUrl,
    };
  }

  async purchaseReport(
    reportType: PurchaseReportTypes,
    reportInfo: Partial<{
      zodiacSign: ZodiacTypes;
      partnerZodiacSign: ZodiacTypes;
      gender: string;
      partnerGender: string;
    }> = {},
  ) {
    return this.httpClient.post('/reports/purchase', {
      product_name: reportType,
      report_data: {
        zodiacSign1: reportInfo.zodiacSign,
        zodiacSign2: reportInfo.partnerZodiacSign,
        gender1: reportInfo.gender,
        gender2: reportInfo.partnerGender,
      },
    });
  }

  async getPackages(): Promise<Package[]> {
    return this.httpClient.get('/packages');
  }
}
