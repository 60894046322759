import React from 'react';
import NameScreen from 'src/components/QuizScreens/NameScreen';
import { NameStepData } from 'src/types';
import { useQuizStep } from 'src/context/QuizContext';

export type NameQuizStepProps = {
  id: string;
};

const NameQuizStep = ({ id }: NameQuizStepProps) => {
  const {
    back,
    next,
    stepData,
    updateUserPreferences,
    userPreferences,
  } = useQuizStep(id);

  if (!stepData) {
    // TODO(Logger)
    // TODO add fallback
    console.warn(`Data for ${id} step not found`);
    return null;
  }

  const {
    content,
    stepOrder,
    totalSteps,
    dataKey,
  } = stepData as NameStepData;

  const initialValue = typeof userPreferences[dataKey] === 'string'
    ? userPreferences[dataKey] as string : '';

  const goBack = async () => back();

  const onSubmit = async (newName: string) => {
    updateUserPreferences(dataKey, newName);
    await next();
  };

  return (
    <NameScreen
      content={content}
      stepOrder={stepOrder}
      totalSteps={totalSteps}
      initialValue={initialValue}
      onSubmit={onSubmit}
      onBack={goBack}
    />
  );
};

export default NameQuizStep;
