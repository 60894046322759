import React from 'react';
import PlaceScreen from 'src/components/QuizScreens/PlaceScreen';
import { PlaceStepData } from 'src/types';
import { useQuizStep } from 'src/context/QuizContext';

export type PlaceQuizStepProps = {
  id: string;
};

const PlaceQuizStep = ({ id }: PlaceQuizStepProps) => {
  const {
    back,
    next,
    stepData,
    updateUserPreferences,
    userPreferences,
  } = useQuizStep(id);

  if (!stepData) {
    // TODO(Logger)
    // TODO add fallback
    console.warn(`Data for ${id} step not found`);
    return null;
  }

  const {
    content,
    stepOrder,
    totalSteps,
    placeDataKey,
    locationDataKey,
  } = stepData as PlaceStepData;

  const initialLocation = typeof userPreferences[locationDataKey] === 'string'
    ? userPreferences[locationDataKey] as string : '';
  const initialCoordinates = Array.isArray(userPreferences[placeDataKey])
    ? userPreferences[placeDataKey] as Array<string> : [];

  const goBack = async () => back();

  const onPlaceChosen = async (coordinates: string[], location: string) => {
    updateUserPreferences(locationDataKey, location);
    updateUserPreferences(placeDataKey, coordinates);
    await next();
  };

  return (
    <PlaceScreen
      content={content}
      onBack={goBack}
      stepOrder={stepOrder}
      totalSteps={totalSteps}
      initialLocation={initialLocation}
      initialCoordinates={initialCoordinates}
      onChosen={onPlaceChosen}
    />
  );
};

export default PlaceQuizStep;
