import { combineReducers } from 'redux';
import { locationReducer } from 'src/location/store';
import { userReducer } from '../user';
import { geoLocationAutocompleteReducer } from '../geoLocationAutocomplete/reducer';
import astrologerReducer from '../astrologer/reducer';
import { cookiesAgreementReducer } from '../cookiesAgreement';

const rootReducer = combineReducers({
  cookiesAgreement: cookiesAgreementReducer,
  userProfile: userReducer,
  geoLocation: geoLocationAutocompleteReducer,
  astrologer: astrologerReducer,
  location: locationReducer,
});

export type RootInitialState = ReturnType<typeof rootReducer>;

export default rootReducer;
