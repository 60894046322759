import React from 'react';
import ButtonBack from 'src/components/common/ButtonBack/ButtonBack';
import Button from 'src/components/common/Button';
import { SupportStepData } from 'src/types';
import { useMarkdown } from 'src/hooks/useMarkdown';
import styles from './SupportScreen.module.scss';

export type SupportScreenProps = {
  onBack: () => void;
  onContinue: () => void;
} & Omit<SupportStepData, 'screenType' | 'id' | 'analytic'>;

const SupportScreen = ({
  onBack,
  onContinue,
  content,
}: SupportScreenProps) => {
  const parsedDescription = useMarkdown(content.description);

  return (
    <div className={styles.container}>
      <div className={styles.backWrapper}>
        <ButtonBack secondary onClick={onBack} />
      </div>
      <h1 className={styles.title}>{content.title}</h1>
      <div
        className={styles.descriptionWrapper}
        dangerouslySetInnerHTML={{ __html: parsedDescription }}
      />
      <ul className={styles.list}>
        {content.list.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>
      <div className={styles.buttonWrapper}>
        <Button
          onClick={onContinue}
          wrapperClassName={styles.buttonContainer}
          className={styles.button}
        >
          Got it
        </Button>
      </div>
    </div>
  );
};

export default SupportScreen;
