import React from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import styles from './Button.module.scss';
import CircularProgress from '../CircularProgress';

export interface ButtonProps {
  link?: string;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  wrapperClassName?: string;
  children?: React.ReactNode;
  icon?: React.ReactNode;
  loading?: boolean;
  id?: string;
  variant?: 'primary' | 'success';
  type?: 'button' | 'submit' | 'reset'
}

const Button = ({
  link,
  disabled,
  variant = 'primary',
  onClick,
  className = '',
  wrapperClassName,
  type = 'button',
  children,
  icon,
  loading,
  id,
}: ButtonProps) => {
  const history = useHistory();
  const handleClick = () => {
    if (loading) return;

    if (onClick) {
      onClick();
    }
    if (link) setTimeout(() => history.push(link), 300);
  };

  const btnClasses = cn({
    [styles.button]: true,
    [styles.disabled]: disabled,
    [styles.buttonSuccess]: variant === 'success',
    [className]: !!className,
  });

  const renderIcon = () => {
    if (loading) {
      return (
        <span className={styles.buttonIcon}>
          <CircularProgress className={styles.loadingIndicator} size={20} />
        </span>
      );
    }

    if (icon) {
      return (
        <span className={styles.buttonIcon}>
          {icon}
        </span>
      );
    }

    return null;
  };

  return (
    <div className={cn(styles.block, wrapperClassName)}>
      { /* eslint-disable react/button-has-type */ }
      <button
        id={id}
        className={btnClasses}
        type={type}
        onClick={handleClick}
        disabled={disabled}
      >
        <span className={styles.buttonLabel}>
          {renderIcon()}
          {children}
        </span>
      </button>
    </div>
  );
};

export default Button;
