import React, { MouseEvent } from 'react';
import cn from 'classnames';
import { ReactComponent as Back } from 'src/assets/images/back.svg';
import styles from './ButtonBack.module.scss';

export type ButtonBackProps = {
  secondary?: boolean;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
}

const ButtonBack = ({ secondary, onClick, className }: ButtonBackProps) => (
  <button
    type="button"
    onClick={onClick}
    className={cn(secondary ? styles.secondary : styles.btn, className)}
  >
    <Back className={styles.img} title="back" />
  </button>
);

export default ButtonBack;
