import React, { useState } from 'react';
import QuizScreenLayout from 'src/components/layout/QuizScreenLayout';
import moment from 'moment';
import { generateDates, generateMonthDays } from 'src/utils/generateDates';
import Select from 'src/components/common/Select/Select';
import Button from 'src/components/common/Button';
import { DateStepData } from 'src/types';
import styles from './DateScreen.module.scss';

export type DateScreenProps = {
  onBack: () => void;
  onSelected: (date: string) => void;
} & Omit<DateStepData, 'id' | 'dataKey' | 'screenType' | 'analytic'>;

const DateScreen = (props: DateScreenProps) => {
  const {
    content,
    onBack,
    onSelected,
    stepOrder,
    totalSteps,
  } = props;

  const [date, setDate] = useState({
    month: 'Jan',
    day: '01',
    year: '1990',
  });

  const daysList = generateMonthDays(date.month, date.year);
  const monthsList = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const yearsList = generateDates(1940, 2004, 'decrement');

  const handleDateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setDate((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleClick = () => {
    const dateOfBirth = moment(`${date.month} ${date.day} ${date.year}`, 'MMM D YYYY').format(
      'YYYY-MM-DD',
    );
    if (dateOfBirth === 'Invalid date') {
      const newDateOfBirth = moment(`${date.month} 01 ${date.year}`, 'MMM D YYYY').format(
        'YYYY-MM-DD',
      );
      return onSelected(newDateOfBirth);
    }
    return onSelected(dateOfBirth);
  };

  return (
    <QuizScreenLayout
      title={content.title}
      illustration={content.illustration}
      stepOrder={stepOrder}
      totalSteps={totalSteps}
      onBack={onBack}
    >
      <div>
        <p className={styles.text}>{content.subtitle}</p>
        <div className={styles.select}>
          <Select data={monthsList} name="month" value={date.month} onChange={handleDateChange} />
          <Select data={daysList} name="day" value={date.day} onChange={handleDateChange} />
          <Select data={yearsList} name="year" value={date.year} onChange={handleDateChange} />
        </div>
      </div>
      <Button onClick={handleClick}>
        Continue
      </Button>
    </QuizScreenLayout>
  );
};

export default DateScreen;
