import React from 'react';

import Header from 'src/components/layout/Header/Header';
import styles from './DefaultLayout.module.scss';

export type DefaultLayoutProps = {
  children?: React.ReactNode
}

const DefaultLayout = ({ children }: DefaultLayoutProps) => (
  <div className={styles.container}>
    <Header />
    <div className={styles.content}>
      {children}
    </div>
  </div>
);

export default DefaultLayout;
